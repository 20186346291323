
import {defineComponent} from "vue"

export default defineComponent({
    name: "redeem-avatar",
    props: {
        url: {
            type: String,
            required: false,
            default: null
        },
        initials: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        size: {
            type: String,
            default: "sm",
            required: false
        },
        fallback: {
            type: String,
            default: "initials",
            required: false
        }
    },
    computed: {
        sizeClass() {
            if (this.size == "xs") {
                return "h-6 w-6";
            }
            if (this.size == "sm") {
                return "h-8 w-8";
            }
            if (this.size == "" || this.size == "md" || this.size == "medium") {
                return "h-10 w-10";
            }
            if (this.size == "lg") {
                return "h-12 w-12";
            }
            if (this.size == "xl") {
                return "h-14 w-14";
            }
            return `h-${this.size} w-${this.size}`;
        },
        fontClass() {
            const baseClass = this.initials == "@" ? "pb-1 " : "";

            if (this.size == "xs"
                || this.size == "sm"
                || this.size == "medium"
                || this.size == "lg"
                || this.size == "xl") {
                return `${baseClass}text-${this.size}`;
            }

            if (this.size == "" || this.size == "md") {
                return "${baseClass}text-medium";
            }

            return `${baseClass}text-${this.size}`;
        },
        avatarUrl() {
            if (this.url) {
                return this.url;
            }

            if (this.name && this.name.toLowerCase().indexOf("gift up") >= 0) {
                return `https://giftup.app/logos/mark.png`;
            }

            return undefined;
        },
        assumedInitials() {
            if (!this.name) {
                return '';
            }

            const name = this.name.split(/\s+/);
            let initials = "";
            try {
                initials += name.slice(0, -1).join(" ").substring(0, 1);
                initials += name.pop().substring(0, 1);
            } catch {}

            return initials;
        }
    }
});
