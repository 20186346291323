
    import { defineComponent } from 'vue';
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import useVuelidate from "@vuelidate/core";
    import { LoginRequest } from "@/api/login";
    import { email } from "@vuelidate/validators";
    import FocusDirective from "@/mixins/FocusDirective";

    export default defineComponent(
        {
            name: 'Home',
            data() {
                return {
                    pin: '',
                    staffEmail: '',
                    submitting: false
                };
            },
            components: {
                'submit-button': SubmitButton,
                'redeem-header': Header
            },
            setup() {
                return {v$: useVuelidate()};
            },
            mixins: [
                FocusDirective
            ],
            validations: {
                pin: {
                    valid: function (value: number) {
                        if (!value) {
                            return false;
                        }

                        return value.toString().length >= 4;
                    }
                },
                staffEmail: {
                    valid: function (value) {
                        if (!value) {
                            return false;
                        }

                        return value.length > 0;
                    },
                    email
                }
            },
            methods: {
                async submit(e) {
                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    this.submitting = true;

                    try {
                        await this.$store.dispatch('user/login', LoginRequest.withEmail(this.staffEmail, this.pin));
                    }
                    finally {
                        this.pin = "";
                        await this.$nextTick(() => {
                            if (this.$refs.pinEntryInput) {
                                this.$refs.pinEntryInput.focus();
                            }
                        });
                        this.v$.$reset();

                        this.submitting = false;
                    }
                }
            }
        });
