import { Module } from "vuex";
import router from "@/router";
import SearchApi, { SearchResponse, SearchResult } from "@/api/search";
import Helpers from "@/Helpers";
import store, {Intercepted401Action} from "@/store";

const state = () => ({
    query: null as string,
    results: null as Array<SearchResult>,
    hasMore: false
});

const actions = {
    async search({ state, commit, rootState }, model: SearchQuery) {
        commit("setIntercepted401Action", { action: "search/search", payload: model } as Intercepted401Action, { root: true });

        document.title = "Searching for " + model.query;

        let queueName = "giftcardnotfound";

        try {
            const response = await SearchApi.search(model.query, model.page);

            let foundGiftCard = response.giftCards[0];

            if (response.giftCards.length == 1
                && model.page == 1
                && foundGiftCard.code.toLowerCase() == model.query.toLowerCase()) {
                commit('setQuery', null);

                if (rootState.company.id != foundGiftCard.companyId) {
                    try {
                        await store.dispatch("user/switchCompany", foundGiftCard.companyId);
                    }
                    catch {
                        await notFound(rootState, queueName, model.query);
                        return;
                    }
                }

                let data = {
                    name: 'Details',
                    params: { companyId: foundGiftCard.companyId, id: foundGiftCard.id }
                };

                if (rootState.lastNavigationRoute
                    && rootState.lastNavigationRoute.name == "Details") {
                    await router.replace(data);
                }
                else {
                    await router.push(data);
                }

                Helpers.clearNotifications(queueName);
            }
            else {
                commit('setQuery', model);
                commit('setResults', response);

                if (response.giftCards.length > 0) {
                    if (model.page == 1) {
                        await router.push({
                            name: 'Search',
                            params: { companyId: rootState.company.id },
                            query: { query: model.query, page: model.page }
                        });
                    } else {
                        await router.replace({
                            name: 'Search',
                            params: { companyId: rootState.company.id },
                            query: { query: model.query, page: model.page }
                        });
                    }

                    Helpers.clearNotifications(queueName);
                }
                else {
                    await notFound(rootState, queueName, model.query);
                }
            }
        } catch (ex) {
            if (ex.responseStatus == 404) {
                await notFound(rootState, queueName, model.query);
            } else if (ex.responseStatus == 500) {
                Helpers.showErrorNotification(`We had an error searching for ${model.query}, please try again`);
            } else {
                throw ex;
            }
        }
    }
};

async function notFound(rootState, queueName, query) {
    await router.replace({ name: 'Redeem', params: { companyId: rootState.company.id } });
    Helpers.clearNotifications(queueName);
    Helpers.showErrorNotification(`A ${rootState.company.singularNomenclature.toLowerCase()} code containing ${query} was not found`, null, queueName);
}

const getters = {
    getByCode: (state) => (code) => {
        return state.results.find(x => x.code == code);
    }
}

const mutations = {
    setQuery(state, model: SearchQuery) {
        state.query = model ? model.query : "";
        state.page = model ? model.page : 1;
    },
    setResults(state, response: SearchResponse) {
        state.results = response.giftCards;
        state.hasMore = response.hasMore;
    }
}

export class SearchQuery {
    query: string;
    page: number;

    static search(query: string, page: number) {
        return { query, page } as SearchQuery;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
} as unknown as Module<any, any>
