
import { defineComponent } from 'vue';

export default defineComponent({
    name: "loading-spinner",
    props: {
        delay: {
            type: Boolean,
            default: false,
            required: false
        }
    }
});
