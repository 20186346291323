import LoginApi, { LoginRequest, LoginResponse, LoginResponseCompany, LoginResponseLocation } from "@/api/login";
import { Module } from "vuex";
import router from "@/router";
import Helpers from "@/Helpers";
import SwitchApi from "@/api/switch";
import PinResetApi, { ChangePinRequest, VerifyPinRequest } from "@/api/pinReset";
import { Intercepted401Action } from "@/store";

export interface UserState {
    email: string;
    id: string;
    avatarUrl: string;
    displayName: string;
    initials: string;
    loginExpired: boolean;
    canRedeemExpiredGiftCards: boolean;
    canRedeemLocationRestrictedGiftCards: boolean;
    availableCompanies: Array<LoginResponseCompany>;
    availableLocations: Array<LoginResponseLocation>;
    companyHasLocations: boolean;
}

function getDefaultState() {
    return <UserState>{
        email: null as string,
        avatarUrl: null as string,
        id: null as string,
        displayName: null as string,
        initials: null as string,
        canRedeemExpiredGiftCards: false,
        canRedeemLocationRestrictedGiftCards: false,
        loginExpired: false,
        availableCompanies: [] as Array<LoginResponseCompany>,
        availableLocations: [] as Array<LoginResponseLocation>,
        companyHasLocations: false
    };
}

async function loginImpl(request: LoginRequest, commit): Promise<LoginResponse> {
    const response = await LoginApi.login(request);

    commit('setLoggedInUser', response);
    commit('company/setCompany', response.company, { root: true });

    return response;
}

const state = getDefaultState();

const incorrectPinQueue = 'incorrect-pin';

const actions = {
    async login({ state, dispatch, commit, rootState }, request: LoginRequest) {
        try {
            const response = await loginImpl(request, commit);

            Helpers.clearNotifications(incorrectPinQueue);

            if (response.locations && response.locations.userLocations.length > 0) {
                if (response.locationId) {
                    await dispatch("setLocation", response.locationId);
                }
                else {
                    // user has locations, and must choose one
                    commit('location/reset', null, { root: true });

                    await router.push({ name: 'ChooseLocation' });

                    return;
                }
            }

            if (request.allowRedirect) {
                if (rootState.lastNavigationRoute) {
                    await router.push(rootState.lastNavigationRoute);

                    commit('setLastNavigationRoute', null, { root: true });
                }
                else {
                    await router.push({ name: 'Redeem', params: { companyId: response.company.id } });
                }
            }
        }
        catch {
            Helpers.clearNotifications(incorrectPinQueue);
            Helpers.showErrorNotification("Incorrect PIN, please try again", null, incorrectPinQueue);

            if (rootState.company.id) {
                await router.push({ name: 'CompanyLogin', params: { companyId: rootState.company.id } });
            }
            else {
                await router.push({ name: 'GenericLogin' });
            }
        }
    },

    async refreshLogin({ state, dispatch, commit, rootState }, request: LoginRequest) {
        await loginImpl(request, commit);
    },

    async setLocation({ state, commit, rootState }, locationId) {
        let location = state.availableLocations.find(x => x.id == locationId);
        commit('location/setLocation', location, { root: true });

        await router.push({ name: 'Redeem', params: { companyId: rootState.company.id } });
    },

    async signout({ commit, rootState }) {
        Helpers.deleteCookie('RedeemToken');

        commit('reset');
        commit('location/reset', null, { root: true });

        commit("setLastNavigationRoute", null, { root: true });
        commit("setIntercepted401Action", null, { root: true });

        await router.push({ name: 'CompanyLogin', params: { companyId: rootState.company.id } });
    },

    async reset({ commit }) {
        commit('reset');
        commit('location/reset', null, { root: true });
    },

    async switchLocation({ commit, dispatch }, locationId: string) {
        commit("setIntercepted401Action", { action: "user/switchLocation", payload: locationId } as Intercepted401Action, { root: true });

        try {
            await SwitchApi.switchLocation(locationId);

            await dispatch("setLocation", locationId);
        }
        catch (error) {
            Helpers.showErrorNotificationFromFetchRejection(error);

            await dispatch('user/refreshCompany', '', { root: true });
        }
    },

    async refreshCompany({ rootState, dispatch }) {
        await dispatch("switchCompany", rootState.company.id);
    },

    async switchCompany({ state, commit, rootState, dispatch }, companyId: string) {
        const changingCompany = companyId != rootState.company.id;

        const response = await SwitchApi.switchCompany(companyId);

        commit('setLoggedInUser', response);
        commit('company/setCompany', response.company, { root: true });

        commit('cloudbeds/resetHotel', null, { root: true });
        commit('giftCard/reset', null, { root: true });

        if (changingCompany) {
            commit('location/reset', null, { root: true });
        }

        if (response.locationId) {
            await dispatch("setLocation", response.locationId);
        }

        await router.push({ name: 'Redeem', params: { companyId: companyId } });
    },

    async startPinReset({ rootState }, email: string) {
        await PinResetApi.startPinReset(email, rootState.company.id);

        let message = `If an account exists in Gift Up! with the email address <u>${email}</u>, you'll get an email shortly with a link so you can reset your pin.`;

        Helpers.showNotification({
                                  text: message,
                                  type: "success",
                                  timeout: 7000,
                              }, "Pin reset email requested");

        if (rootState.company.id) {
            await router.push({ name: 'CompanyLogin', params: { companyId: rootState.company.id } });
        }
        else {
            await router.push({ name: 'GenericLogin' });
        }
    },

    async verifyPinReset({ commit, rootState }, request: VerifyPinRequest) {
        try {
            const response = await PinResetApi.verifyPinToken(request);

            commit('setDisplayName', response.userDisplayName);
        }
        catch {
            Helpers.showErrorNotification("The link you clicked is incorrect or has expired, please request another link", "Cannot reset your pin");

            await router.push({ name: 'PinReset' });
        }
    },

    async completePinReset({ commit, rootState }, request: ChangePinRequest) {
        await PinResetApi.completePinReset(request);

        commit('setUserId', request.userId);

        Helpers.showSuccessNotification("Please log in using your new pin", "Pin successfully changed");

        if (rootState.company.id) {
            await router.push({ name: 'CompanyLogin', params: { companyId: rootState.company.id } });
        }
        else {
            await router.push({ name: 'GenericLogin' });
        }
    },

    async expired({ commit }) {
        commit('expire');
    }
};

const getters = {
    isLoggedIn(state) {
        return state.email && !state.loginExpired;
    },

    mustChooseLocation(state, getters, rootState) {
        return state.availableLocations.length > 0 && rootState.location?.id == null;
    }
};

const mutations = {
    setLoggedInUser(state: UserState, response: LoginResponse) {
        const loggedInUser = {
            email: response.user.email,
            id: response.user.id,
            avatarUrl: response.user.avatarUrl,
            displayName: response.user.displayName,
            initials: response.user.initials,
            canRedeemExpiredGiftCards: response.user.canRedeemExpiredGiftCards,
            canRedeemLocationRestrictedGiftCards: response.user.canRedeemLocationRestrictedGiftCards,
            locationId: response.locationId,
            availableCompanies: response.companies,
            companyHasLocations: response.locations.allLocations.length > 0,
            availableLocations: response.locations.userLocations,
        };

        Object.assign(state, loggedInUser);
        state.loginExpired = false;
    },

    setUserId(state, userId){
        state.id = userId;
    },

    setDisplayName(state, displayName){
        state.displayName = displayName
    },

    expire(state) {
        state.loginExpired = true;
    },

    reset(state) {
        Object.assign(state, getDefaultState());
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
} as unknown as Module<any, any>
