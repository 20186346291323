
    import {defineComponent} from 'vue';
    import useVuelidate from "@vuelidate/core";
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import Alert from "@/components/Alert.vue";
    import Avatar from "@/components/Avatar.vue";
    import LocationPicker from "@/components/LocationPicker.vue";
    import SelectPicker from "@/components/SelectPicker.vue";
    import FocusDirective from "@/mixins/FocusDirective";
    import {LoginRequest} from "@/api/login";
    import {ArrowPathIcon, UsersIcon} from '@heroicons/vue/24/outline';
    import {ChevronRightIcon} from '@heroicons/vue/24/solid';
    import {CompanyLoginResponseUser} from "@/api/company";
    import {UserState} from "@/store/modules/user";
    import {email} from "@vuelidate/validators";
    import NoNetworkIcon from "@/components/NoNetworkIcon.vue";
    import Helpers from "@/Helpers";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";

    export default defineComponent(
        {
            components: {
                'alert': Alert,
                'redeem-avatar': Avatar,
                'select-picker': SelectPicker,
                'location-picker': LocationPicker,
                'submit-button': SubmitButton,
                'redeem-header': Header,
                'no-network-icon': NoNetworkIcon,
                'loading-spinner': LoadingSpinner,
                ArrowPathIcon,
                ChevronRightIcon,
                UsersIcon
            },
            props: {
                companyIdRoute: String
            },
            data() {
                return {
                    selectedLocation: null,
                    selectedUserId: '',
                    locationId: '',
                    showDisabledUsers: false,
                    pin: '',
                    staffEmail: '',
                    submitting: false,
                    submittingLocation: false,
                    searchUsers: '',
                    companyIsSuspended: false,
                    showFewerUsers: false,
                    userCountToShow: 5,
                    failedToLoad: false,
                    publicPath: process.env.BASE_URL
                };
            },
            setup() {
                return {v$: useVuelidate()};
            },
            async created() {
                try {
                    await this.$store.dispatch("company/getDetails", this.companyIdRoute);

                    if (this.visibleUsers.length > this.userCountToShow + 1) {
                        this.showFewerUsers = true;
                    }

                    if (this.users.length == 1
                        && !this.hasSelectedUser) {
                        this.selectedUserId = this.users[0].id;
                    }

                    this.locationId = this.location?.id;
                } catch {
                    this.failedToLoad = true;
                }
            },
            mixins: [
                FocusDirective
            ],
            validations: {
                pin: {
                    valid: function (value: number) {
                        if (!value) {
                            return false;
                        }

                        return value.toString().length >= 4;
                    }
                },
                staffEmail: {
                    valid: function (value) {
                        if (!this.loginWithEmail) {
                            return true;
                        }

                        if (!value) {
                            return false;
                        }

                        return value && this.loginWithEmail;
                    },
                    email
                },
                locationId: {
                    valid: function (value) {
                        if (this.selectedUser && this.selectedUser.locations.length > 0) {
                            return !!value;
                        }

                        return true;
                    }
                }
            },
            provide() {
                return {
                    "$parent.v$": this.v$
                };
            },
            computed: {
                company() {
                    return this.$store.state.company;
                },
                user(): UserState {
                    return this.$store.state.user;
                },
                location() {
                    return this.$store.state.location;
                },
                users(): CompanyLoginResponseUser[] {
                    let actualUsers = this.$store.state.company.login.users;

                    if (this.canSignInWithEmail) {
                        actualUsers.push(this.loginWithEmailUser);
                    }

                    return actualUsers;
                },
                newCompanyId() {
                    return this.$store.state.company.login.changedCompanyId;
                },
                canSignInWithEmail() {
                    return this.$store.state.company.login.canSignInWithEmail;
                },
                isSignedIn() {
                    return !!this.currentUserId;
                },
                currentUserId() {
                    const currentUser = this.$store.state.user;

                    if (currentUser) {
                        return currentUser.id;
                    }

                    return null;
                },
                mustChooseLocation() {
                    return this.$store.getters["user/mustChooseLocation"];
                },
                usersToShow(): CompanyLoginResponseUser[] {
                    let users = this.visibleUsers;

                    if (this.hasSelectedUser) {
                        return users.filter(s => s.id == this.selectedUserId);
                    }

                    const usersToShowLimit = this.showFewerUsers ? this.userCountToShow : 9999999;

                    let usersToReturn = users.slice(0, usersToShowLimit);

                    if (this.showDisabledUsers || (this.searchUsers && !this.hasSelectedUser)) {
                        usersToReturn = users.concat(this.disabledUsers).slice(0, usersToShowLimit);
                    }

                    return usersToReturn;
                },
                allUsersFilteredBySearchTerm(): CompanyLoginResponseUser[] {
                    let users = this.users;

                    if (this.searchUsers) {
                        let searchTerm = this.searchUsers.replace("*", ".*");
                        const regex = new RegExp(searchTerm, "ig");
                        users = users.filter(s => s.displayName.search(regex) >= 0);
                    }

                    return users;
                },
                allVisibleUsers(): CompanyLoginResponseUser[] {
                    return this.users.filter(s => !s.disabledMessage);
                },
                allDisabledUsers(): CompanyLoginResponseUser[] {
                    return this.users.filter(s => s.disabledMessage);
                },
                visibleUsers(): CompanyLoginResponseUser[] {
                    return this.allUsersFilteredBySearchTerm.filter(s => !s.disabledMessage);
                },
                disabledUsers(): CompanyLoginResponseUser[] {
                    return this.allUsersFilteredBySearchTerm.filter(s => s.disabledMessage);
                },
                showDisabledUsersRow() {
                    return !this.showDisabledUsers
                        && this.disabledUsers.length > 0
                        && !this.searchUsers
                        && !this.showExtraUsersButton
                        && !this.hasSelectedUser;
                },
                showExtraUsersButton() {
                    return this.showFewerUsers
                        && this.visibleUsers.length - this.userCountToShow > 0
                        && !this.hasSelectedUser;
                },
                showUserSearchBar() {
                    return this.allVisibleUsers.length >= 10
                        && this.hasSelectedUser === false;
                },
                selectedUser() {
                    return this.users.find(x => x.id == this.selectedUserId || x.id == this.currentUserId);
                },
                hasSelectedUser() {
                    return !!this.selectedUser;
                },
                suspendedMessage() {
                    return `You have outstanding invoices on your ${this.companyName} Gift Up! account ` +
                        `and you can no longer redeem ${this.company.pluralNomenclature.toLowerCase()} until ` +
                        `the outstanding balance is settled.`;
                },
                noPinMessage() {
                    return `You have not yet created a pin so you are unable to redeem ${this.company.pluralNomenclature.toLowerCase()} ` +
                        `using the redeem app.`;
                },
                noUsersFoundMessage() {
                    return `No users found with the search term '${this.searchUsers}'`;
                },
                online() {
                    return this.$store.state.online;
                },
                loginWithEmailUser() {
                    return {
                        id: Helpers.emptyGuid,
                        name: "",
                        displayName: "Log in with email",
                        initials: "@",
                        hasPinNumber: true,
                        isHiddenInRedeemApp: false,
                        canLogin: true,
                        locations: []
                    };
                },
                loginWithEmail() {
                    return this.hasSelectedUser && this.selectedUserId === this.loginWithEmailUser.id;
                }
            },
            watch: {
                searchUsers() {
                    this.selectedUserId = null;
                }
            },

            methods: {
                async submit() {
                    if (this.showUserSearchBar) {
                        return;
                    }

                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    this.submitting = true;

                    let userId = this.selectedUserId;

                    if (this.selectedUser && this.selectedUser.id) {
                        userId = this.selectedUser.id;
                    }

                    let loginRequest = LoginRequest.withId(userId,
                        this.company.id,
                        this.pin,
                        this.locationId)

                    if (this.loginWithEmail) {
                        loginRequest = LoginRequest.withEmail(this.staffEmail, this.pin, this.company.id);
                    }

                    await this.$store.dispatch('user/login', loginRequest);
                    this.pin = "";

                    await this.$nextTick(() => {
                        if (this.$refs.pinEntryInput) {
                            this.$refs.pinEntryInput.focus();
                        }
                    });
                    this.v$.$reset();

                    this.submitting = false;
                },
                setUser(id) {
                    if (id == this.selectedUserId) {
                        this.selectedUserId = null;
                        return;
                    }

                    const user = this.users.find(x => x.id == id);

                    if (user && user.canLogin) {
                        this.selectedUserId = id;
                    }
                },
                onBarcodeScan() {
                    this.$nextTick(() => {
                        this.submit();
                    });
                },
                scrollToPinEntry() {
                    const elem = document.getElementById("pin");
                    if (elem) {
                        elem.focus();
                    }

                    try {
                        let bodyRect = document.body.getBoundingClientRect(),
                            elemRect = this.$refs["pinEntry"].getBoundingClientRect(),
                            offset = elemRect.top - bodyRect.top;

                        window.scrollTo(0, offset - 95);
                    } catch (e) {
                    }
                },
                async goToGenericLogin() {
                    await this.$store.dispatch("forceGenericHome");
                },
                reload() {
                    window.location.reload();
                },
                async goToPinReset() {
                    await this.$router.push({name: 'PinReset'});
                },
                async signOut() {
                    await this.$store.dispatch('user/signout');
                }
            }
        });
