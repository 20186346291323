
    import { defineComponent } from "vue"
    import Avatar from "./Avatar.vue";
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    import Helpers from "@/Helpers";
    import { UserState } from "@/store/modules/user";
    import { CompanyLoginResponse, CompanyLoginResponseUser } from "@/api/company";
    import { LoginResponseCompany } from "@/api/login";
    import {CompanyState} from "@/store/modules/company";

    export default defineComponent(
        {
            name: "redeem-header",
            data: function () {
                return {
                    username: '',
                    initials: '',
                    email: ''
                };
            },
            components: {
                'redeem-avatar': Avatar,
                Popover,
                PopoverButton,
                PopoverPanel
            },
            computed: {
                company() : CompanyState {
                    return this.$store.state.company;
                },
                user(): UserState {
                    return this.$store.state.user;
                },
                location() {
                    return this.$store.state.location;
                },
                homeRoute() {
                    if (this.company.id) {
                        return { name: 'Redeem', params: { companyId: this.company.id } }
                    }

                    return { name: 'GenericLogin' };
                },
                logoUrlWithFallback() {
                    if (this.company.logoUrl) {
                        return this.company.logoUrl;
                    }

                    return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MjkuMDEgMjA0LjI1IiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxnIGZpbGw9IiNmZmYiPjxwYXRoIGQ9Ik0zMjAuNjEgMjQuNzZoLTI3LjM0bDkuNjgtMTQuNSAzLjk5LTUuOTcgMi44NiA0LjI5IDEwLjgxIDE2LjE4eiIvPjxjaXJjbGUgY3g9IjMzMC4yIiBjeT0iMTA0Ljc5IiByPSI5Ny40MSIvPjwvZz48cGF0aCBkPSJNMzUyLjIyIDgwLjg1aC0xMS44MXYyMS4yNWgxMi4xMWM3LjY0IDAgMTIuMTEtNC41NyAxMi4xMS0xMC41MnYtLjJjMC02Ljg2LTQuNzYtMTAuNTMtMTIuNDEtMTAuNTN6bS0yMi4xMS03NC41YTk5LjI5IDk5LjI5IDAgMCAwLTE0Ljc5IDEuMTJMMzAzLjg5IDBsMTYuMzkgMjQuNThoMGwtLjIxLS42MWgtNS41N3Y4Mi4zYzAgMjEtMTEuODEgMzEuMzctMzAuNDggMzEuMzdzLTMwLjA4LTEwLjQyLTMwLjA4LTMwLjg3VjY3LjA1aDE1LjI5djM5LjMxYzAgMTEuMzIgNS42NiAxNy4xNyAxNSAxNy4xN3MxNS01LjY2IDE1LTE2LjY4VjI0aC01LjM2bDkuMjMtMTMuODVhOTguOTMgOTguOTMgMCAxIDAgMjctMy43N3ptNTAgODQuOTNjMCAxNi4wOC0xMi41MSAyNC40Mi0yOC4wOSAyNC40MmgtMTEuNjF2MjAuODVoLTE1LjI5di02OS41aDI4LjM5YzE2LjU4IDAgMjYuNjEgOS44MyAyNi42MSAyNHptMjIuMTMgNDUuMjdoLTE2LjA3di0xNS44OWgxNi4wOHptLTMuODctMjMuNjNIMzkwTDM4NS4zOCA2OXYtMkg0MDN2MnoiIGZpbGw9IiNmYzAiLz48cGF0aCBkPSJNMzcuMjkgNjUuOTJjMTQuNDkgMCAyMy42MiA3IDI5IDE1LjMzTDUzLjg4IDg4YTE5Ljg3IDE5Ljg3IDAgMCAwLTE2LjU5LTguODJjLTEyLjcgMC0yMS45NCA5Ljc2LTIxLjk0IDIzczkuMjQgMjMgMjEuOTQgMjNhMjMuNjYgMjMuNjYgMCAwIDAgMTUuMTItNS4zNXYtOC41SDMzLjcyVjk4LjQ3aDMzLjM5djI2Ljc3YTM4Ljg3IDM4Ljg3IDAgMCAxLTI5LjgyIDEzLjIzQzE2LjgyIDEzOC40NyAwIDEyNC4xOSAwIDEwMi4xNXMxNi44Mi0zNi4yMyAzNy4yOS0zNi4yM3ptMzkuNTkgMS4xNmgxNC45MXY3MEg3Ni44OHptMjYuNjcgMGg0OS41NXYxMi44MWgtMzQuNjV2MTUuMjJoMzMuOTF2MTIuODFoLTMzLjkxdjI5LjE5aC0xNC45em04MC4yOCAxMy4xMmgtMjAuNDdWNjcuMDhoNTUuODZWODAuMmgtMjAuMzd2NTYuOTFoLTE1eiIvPjwvc3ZnPg==';
                },
                logoutUrl() {
                    return `/${this.company.id}/redeem/logout`;
                },
                isLoggedIn() {
                    return this.user && this.user.email;
                },
                fallback() {
                    return this.user.initials ? "initials" : "avatar";
                }
            },
            methods: {
                async logout() {
                    await this.$store.dispatch("user/signout");
                },
                async resetUser() {
                    await this.$store.dispatch("user/reset");
                },
                async switchCompany(c: LoginResponseCompany) {
                    if(c.canLogin == false){
                        return;
                    }
                    await this.$store.dispatch("user/switchCompany", c.id);

                    this.$refs.popoverButton.$el.click();
                },
                async switchLocation(id) {
                    await this.$store.dispatch("user/switchLocation", id);

                    this.$refs.popoverButton.$el.click();
                },
                async goToGenericLogin() {
                    await this.$store.dispatch("forceGenericHome");
                }
            }
        });
