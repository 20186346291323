
    import {defineComponent} from "vue"
    import SelectPicker from "./SelectPicker.vue";
    import Helpers from "@/Helpers";

    export default defineComponent({
        name: "location-picker",
        components: {
            'select-picker': SelectPicker
        },
        props: {
            modelValue: String,
            locations: Array,
            formName: String,
            tabindex: {
                type: Number,
                default: null,
                required: false
            },
            showValidationError: Boolean,
            showLabel: {
                type: Boolean,
                default: true,
                required: false
            },
            labelClass: {
                type: String,
                required: false
            },
        },
        watch: {
            selectedLocation: function (location) {
                this.$emit('update:modelValue', location);
            },
            modelValue: function (value) {
                this.selectedLocation = value;
            }
        },
        data() {
            return {
                selectedLocation: this.modelValue
            }
        },
        created() {
            if (this.locations.length == 1) {
                this.selectedLocation = this.locations[0].id;
            }
        },
        methods: {
            optionRender(option) {
                return `<span class="flex justify-start items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span>${option.name}</span>
                        </span>`;
            }
        }
    });
