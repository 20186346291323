export class HostingEnvironmentFactory {
    static get(env: string = undefined,
               devStorageOverride: string = undefined,
               devPortOverride: string = undefined): IHostingEnvironment {

        const productionHostingEnvironment: IHostingEnvironment = {
            env: HostingEnv.Production,
            apiUrl: "https://redeem.giftup.app/api"
        };
        const betaHostingEnvironment: IHostingEnvironment = {
            env: HostingEnv.Beta,
            apiUrl: "https://betaredeem.giftup.app/api",
        };
        const developmentHostingEnvironment: IHostingEnvironment = {
            env: HostingEnv.Development,
            apiUrl: devPortOverride ? `https://devapi.giftup.app:${devPortOverride}/api` : "https://dev.giftup.app:7074/api",
        };

        let environment = HostingEnv.Production;

        if(process.env.NODE_ENV === 'development'){
            environment = HostingEnv.Development;
        }

        if (env && env.length > 0) {
            switch (env.toLowerCase()) {
                case "staging":
                case "beta":
                    environment = HostingEnv.Beta;
                    break;
                case "development":
                case "dev":
                    environment = HostingEnv.Development;
            }
        } else {
            if (window.location.host.indexOf("beta") >= 0) {
                environment = HostingEnv.Beta;
            } else if (window.location.host.indexOf("dev.") >= 0) {
                environment = HostingEnv.Development;
            }
        }

        if (environment == HostingEnv.Development && window.location.host.indexOf("ngrok.io") >= 0) {
            developmentHostingEnvironment.apiUrl = `https://giftupredeem.eu.ngrok.io/api`;
        }

        switch (environment) {
            case HostingEnv.Beta:
                return betaHostingEnvironment;

            case HostingEnv.Development:
                return developmentHostingEnvironment;
        }

        return productionHostingEnvironment;
    }
}

export enum HostingEnv {
    Production = "Production",
    Beta = "Beta",
    Development = "Development",
}

export interface IHostingEnvironment {
    env: HostingEnv;
    apiUrl: string;
}
