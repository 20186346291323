import { BaseApi } from "@/api/baseApi";
import { RedeemResponse } from "@/api/redeem";

export default class CloudBedsApi extends BaseApi {
    static async listHotels(): Promise<ListHotelsResponse> {
        return this.get(`cloudbeds/list-hotels`);
    }

    static async search(request: SearchRequest): Promise<SearchResponse[]>{
        return this.post(`cloudbeds/find-reservation`, request);
    }

    static async getReservation(propertyId: string, reservationId: string): Promise<SearchResponse>{
        return this.get(`cloudbeds/${propertyId}/reservation/${reservationId}`);
    }

    static async redeem(request: CloudbedsRedeemRequest): Promise<RedeemResponse> {
        if (request.type == 'partial') {
            return this.post(`cloudbeds/redeem-partially/${request.id}`, request);
        }

        return this.post(`cloudbeds/redeem-fully/${request.id}`);
    }

}

export class SearchRequest {
    propertyId: string;
    roomName: string;

    static search(propertyId: string, roomName: string) {
        return { propertyId, roomName } as SearchRequest;
    }
}

export class CloudbedsRedeemRequest {
    id: string;
    companyId: string;
    propertyId: string;
    reservationId: string;
    value: number;
    type: string;

    static partially(id: string, propertyId: string, reservationId: string, value: number): CloudbedsRedeemRequest {
        return { id, value, propertyId, reservationId, type: 'partial' } as CloudbedsRedeemRequest;
    }

    static fully(id: string, reservationId: string) {
        return { id, reservationId, type: 'full' } as CloudbedsRedeemRequest;
    }
}

export class CloudbedsRefreshReservationRequest {
    propertyId: string;
    reservationId: string;
}

export interface ListHotelsResponse {
    hotels: Array<CloudBedsHotel>;
}

export interface CloudBedsHotel {
    propertyId: string;
    propertyName: string;
}

export interface SearchResponse {
    balance: number;
    guestName: string;
    startDate: Date;
    endDate: Date;
    roomName: string;
    reservationId: string;
}
