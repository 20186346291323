
    import {defineComponent} from "vue"
    import {toDate} from "date-fns-tz";
    import {isAfter, parseISO} from "date-fns";
    import Helpers from "../Helpers";

    export default defineComponent(
        {
            name: "status-badge",
            props: {
                redeemedOn: {
                    type: String,
                    required: false,
                    default: null
                },
                expiresOn: {
                    type: String,
                    required: false,
                    default: null
                },
                voidedOn: {
                    type: String,
                    required: false,
                    default: null
                },
                validFrom: {
                    type: String,
                    required: false,
                    default: null
                },
                showDates: {
                    type: Boolean,
                    required: false,
                    default: false
                },
                timeZone: {
                    type: String,
                    required: true,
                    default: ""
                },
                language: {
                    type: String,
                    required: false,
                    default: ""
                },
                now: {
                    type: Date,
                    required: false,
                    default: parseISO(new Date().toISOString())
                }
            },
            computed: {
                hasExpired(): boolean {
                    try {
                        if (this.expiresOn) {
                            const expiresOn = toDate(this.expiresOn, {timeZone: 'UTC'});

                            return isAfter(this.now, expiresOn);
                        }
                    } catch {
                    }

                    return false;
                },

                notYetValid(): boolean {
                    try {
                        if (this.validFrom) {
                            const validFrom = toDate(this.validFrom, {timeZone: 'UTC'});

                            return isAfter(validFrom, this.now);
                        }
                    } catch {
                    }

                    return false;
                },

                statusText() {
                    if (this.voidedOn) {
                        if (this.showDates) {
                            return "Voided on " + Helpers.formatDate(this.voidedOn, this.now, this.timeZone);
                        }

                        return "Voided";
                    }

                    if (this.redeemedOn) {
                        if (this.showDates) {
                            return "Redeemed on " + Helpers.formatDate(this.redeemedOn, this.now, this.timeZone);
                        }

                        return "Redeemed";
                    }

                    if (this.hasExpired) {
                        if (this.showDates) {
                            return "Expired on " + Helpers.formatDate(this.expiresOn, this.now, this.timeZone);
                        }

                        return "Expired";
                    }

                    if (this.notYetValid) {
                        if (this.showDates) {
                            return "Valid from " + Helpers.formatDateTime(this.validFrom, this.now, this.timeZone, this.language).replace(" 00:00", "");
                        }

                        return "Not yet valid";
                    }

                    if (this.expiresOn && this.showDates) {
                        return "Active - expires " + Helpers.formatDate(this.expiresOn, this.now, this.timeZone);
                    }

                    return "Active";
                },

                badgeClass() {
                    if (this.voidedOn) {
                        return "bg-gray-100 text-gray-800";
                    }

                    if (this.redeemedOn) {
                        return "bg-blue-100 text-blue-800";
                    }

                    if (this.hasExpired) {
                        return "bg-red-100 text-red-800";
                    }

                    if (this.notYetValid) {
                        return "bg-yellow-100 text-yellow-800";
                    }

                    return "bg-green-100 text-green-800";
                }
            }
        });
