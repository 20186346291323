
    import { defineComponent } from "vue"
    import SubmitButton from "./SubmitButton.vue";
    import BarcodeScanner from "./BarcodeScanner.vue";
    import FocusDirective from "@/mixins/FocusDirective";
    import Helpers from "@/Helpers";

    export default defineComponent(
        {
            name: "search-and-scan",
            components: {
                'submit-button': SubmitButton,
                'barcode-scanner': BarcodeScanner
            },
            mixins: [
                FocusDirective
            ],
            props: {
                modelValue: {
                    type: String,
                    required: false,
                    default: ""
                },
                label: {
                    type: String,
                    required: false
                },
                placeholder: {
                    type: String,
                    required: false,
                    default: "e.g. ABC123"
                },
                submitButton: {
                    type: Boolean,
                    required: false,
                    default: false
                },
                submitting: {
                    type: Boolean,
                    required: false,
                    default: false
                }
            },
            inject: ["$parent.v$"],
            emits: ['submit', 'update:modelValue'],
            data: function () {
                return {
                    query: this.modelValue,
                    hasScannerAttached: false,
                    nativeAppQueryValueWatchId: ""
                };
            },
            computed: {
                enableScanning() {
                    return this.$store.state.company.enableScanning;
                },
                singularNomenclature() {
                    return this.$store.state.company.singularNomenclature;
                },
                displayLabel() {
                    return this.label ?? "Search for a " + this.company.singularNomenclature.toLowerCase()
                },
                company(){
                    return this.$store.state.company;
                },
                isAndroidApp() {
                    return typeof ((window as any).GiftUpApp) !== "undefined";
                },
                isIosApp() {
                    try {
                        if (typeof((window as any).webkit.messageHandlers.GiftUpApp) !== "undefined") {
                            return typeof((window as any).webkit.messageHandlers.GiftUpApp) !== "undefined";
                        }
                    } catch (e) {}

                    return false;
                },
                isNativeApp() {
                    return this.isAndroidApp || this.isIosApp;
                }
            },
            methods: {
                onBarcodeScanLoad(scanningSupported: boolean) {
                    this.hasScannerAttached = scanningSupported;
                },

                onBarcodeScanStart() {
                    // We have to look for the input's value change as the native apps do not fire
                    // element.dispatchEvent(new Event("input")), so Vue does not get to see the value change
                    if (this.isNativeApp) {
                        if (this.isAndroidApp) {
                            (window as any).GiftUpApp.BeginScan();
                        }
                        else if (this.isIosApp) {
                            (window as any).webkit.messageHandlers.GiftUpApp.postMessage('command:scan');
                        }

                        // Clear out past values
                        const queryElement = this.$refs.query as HTMLInputElement;
                        if (queryElement && queryElement.value) {
                            queryElement.value = "";
                        }
                        this.query = "";

                        if (!this.nativeAppQueryValueWatchId) {
                            const self = this;

                            this.nativeAppQueryValueWatchId = setInterval(() => {
                                if (queryElement
                                    && queryElement.value
                                    && queryElement.value != self.query) {
                                    this.onBarcodeScanEnd(queryElement.value);
                                    clearTimeout(self.nativeAppQueryValueWatchId);
                                    self.nativeAppQueryValueWatchId = null;
                                }
                            }, 250);
                        }
                    }
                },

                onBarcodeScanEnd(code: string) {
                    this.query = code;
                    this.submit();
                },

                submit() {
                    this.updateModelValue(this.query);
                    this.$emit("submit");
                },

                updateModelValue(value) {
                    this.$emit("update:modelValue", value);
                }
            },
            watch: {
                query() {
                    this.updateModelValue(this.query);
                }
            },
            mounted(){
                this.$nextTick(() => {
                    Helpers.setAppPageMounted();
                });
            }
        });
