import { Module } from "vuex";

function getDefaultState() {
    return {
        id: null as string,
        name: null as string
    };
}

const state = getDefaultState();

const actions = {
};

const mutations = {
    setLocation (state, location){
        Object.assign(state, location);
    },

    reset(state){
        Object.assign(state, getDefaultState());
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
} as unknown as Module<any, any>
