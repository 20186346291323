import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "block truncate"
}
const _hoisted_3 = {
  key: 1,
  class: "block truncate text-gray-500"
}
const _hoisted_4 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxLabel = _resolveComponent("ListboxLabel")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    as: "div",
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
  }, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createBlock(_component_ListboxLabel, {
            key: 0,
            class: "block text-sm font-medium text-gray-700 mb-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, {
          as: "div",
          tabindex: _ctx.tabindex,
          class: "text-sm bg-white relative border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-light focus:border-primary-light sm:text-sm"
        }, {
          default: _withCtx(() => [
            (_ctx.selected)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getSelectedText), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title ? _ctx.title : "Please select…"), 1)),
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_ChevronDownIcon, {
                class: "h-5 w-5 text-gray-400",
                "aria-hidden": "true"
              })
            ])
          ]),
          _: 1
        }, 8, ["tabindex"]),
        _createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ListboxOptions, { class: "text-sm absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (option) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    as: "template",
                    key: option.value,
                    value: _ctx.getValue(option)
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass(["text-sm cursor-default select-none relative py-2 pl-3 pr-9 text-gray-900 transition-colors duration-75", [active ? 'bg-gray-100' : 'bg-white']])
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'block truncate']),
                          innerHTML: _ctx.renderText(option)
                        }, null, 10, _hoisted_5),
                        selected
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass([active ? 'text-gray-900' : 'text-gray-900', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                            }, [
                              _createVNode(_component_CheckIcon, {
                                class: "h-5 w-5",
                                "aria-hidden": "true"
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}