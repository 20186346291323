import { Module } from "vuex";
import DetailsApi from "@/api/details";
import RedeemApi, { AddReasonRequest, RedeemRequest, RedeemResponse } from "@/api/redeem";
import Helpers from "@/Helpers";
import router from "@/router";
import {Intercepted401Action} from "@/store";
import {SearchQuery} from "@/store/modules/search";

export interface GiftCardState {
    isStale: boolean;
    companyId: string;
    voidedOn: string;
    code: string;
    title: string;
    expiresOn: string;
    validFrom: string;
    redeemedOn: string;
    recipientName: string;
    artworkUrl: string;
    remainingValue: number;
    remainingUnits: number;
    initialUnits: number;
    backingType: string;
    id: string;
    orderMode: string;
    orderId: string;
    currency: string;
    language: string;
    tip: number;
    canBeRedeemed: boolean;
    notYetValid: boolean;
    isVoided: boolean;
    hasExpired: boolean;
    isRedeemed: boolean;
    terms: string;
    redeemResult: RedeemResponse;
    redemptionLocationIds: string[],
    log: any[];
    customFields: any[];
}

function getDefaultState() {
    return <GiftCardState>{
        isStale: true,
        companyId: null as string,
        voidedOn: null as string,
        code: null as string,
        title: null as string,
        expiresOn: null as string,
        validFrom: null as string,
        redeemedOn: null as string,
        recipientName: null as string,
        artworkUrl: null as string,
        remainingValue: null as number,
        remainingUnits: null as number,
        initialUnits: null as number,
        backingType: "" as string,
        id: null as string,
        orderMode: "",
        orderId: null as string,
        currency: null as string,
        language: null as string,
        tip: null as number,
        canBeRedeemed: null as boolean,
        notYetValid: null as boolean,
        isVoided: null as boolean,
        hasExpired: null as boolean,
        isRedeemed: null as boolean,
        terms: null as string,
        redeemResult: null as RedeemResponse,
        redemptionLocationIds: [],
        log: [],
        customFields: []
    };
}

const state = getDefaultState();

const actions = {
    async getDetails({ state, dispatch, commit, rootState }, id: string) {
        try {
            await dispatch('refreshDetails', id);
        }
        catch (ex) {
            if (ex.responseStatus == 404) {
                Helpers.showWarningNotification(`Could not find ${rootState.company.singularNomenclature.toLowerCase()}, please try again`);
                return;
            }

            if (ex.responseStatus != 401) {
                if (rootState.search.query) {
                    await router.push({ name: 'Search', params: { query: rootState.search.query, page: 1 }});
                } else {
                    await router.replace("/");
                }

                return;
            }

            throw ex;
        }
    },

    async refreshDetails({ state, commit, rootState }, id: string){
        try {
            commit('markAsStale');

            if (id != state.id) {
                commit('reset');
            }

            const response = await DetailsApi.details(id);

            commit('setGiftCard', response);
        }
        catch (ex) {
            if (ex.responseStatus == 404) {
                Helpers.showWarningNotification(`Could not find ${rootState.company.singularNomenclature.toLowerCase()}, please try again`);
            }

            commit('markAsNotStale');

            throw ex;
        }
    },

    async redeem({ state, dispatch, commit, rootState }, request: RedeemRequest) {
        commit("setIntercepted401Action",
               { action: "giftCard/redeem", payload: request } as Intercepted401Action,
               { root: true });

        try {
            const response = await RedeemApi.redeem(request);

            commit('setRedeemResult', response);

            commit('search/setQuery', null, { root: true });
        } catch (ex) {
            commit('setRedeemResult', null);

            if (ex?.responseStatus != 0 && ex?.responseStatus != 401) {
                let errorMessage = "";

                if (typeof ex.responseContent === "string") {
                    errorMessage = ex?.responseContent;
                }
                else if (typeof ex.responseContent === "object") {
                    errorMessage = ex?.responseContent?.failureReason;
                }

                let message = `Could not redeem ${rootState.company.singularNomenclature.toLowerCase()}`;

                if (ex?.responseStatus != 400) {
                    message += ", please try again";
                }

                Helpers.showWarningNotification(errorMessage, message);

                if (typeof ex.responseContent === "object") {
                    if (ex.responseContent?.action == "SwitchLocation") {
                        await dispatch('user/refreshCompany', '', { root: true });
                    }
                }

                return;
            }
        }

        await dispatch('refreshDetails', state.id);
    },

    async undoRedeem({ state, dispatch, commit }, transactionId: string){
        commit("setIntercepted401Action", { action: "giftCard/undoRedeem", payload: transactionId } as Intercepted401Action, { root: true });

        try {
            const response = await RedeemApi.undo(transactionId);

            commit('setRedeemResult', null);

            Helpers.showSuccessNotification(`${response} added back on`, "Redemption reversed");
        }
        catch (ex) {
            if (ex?.responseStatus != 0 && ex?.responseStatus != 401) {
                Helpers.showWarningNotification(ex?.responseContent, "Could not undo redemption, please try again");
            }
        }

        await dispatch('refreshDetails', state.id);
    },

    async addReason({state, dispatch, commit, rootState }, request: AddReasonRequest){
        commit("setIntercepted401Action", { action: "giftCard/addReason", payload: request } as Intercepted401Action, { root: true });

        try {
            const response = await RedeemApi.addReason(request);

            commit('setReasonAdded');

            Helpers.showSuccessNotification(response, `Reason added to ${rootState.company.singularNomenclature.toLowerCase()}`);
        }
        catch (ex){
            if (ex?.responseStatus != 0 && ex?.responseStatus != 401) {
                Helpers.showWarningNotification(ex?.responseContent, `Could not add reason to ${rootState.company.singularNomenclature.toLowerCase()}`);
            }
        }

        await dispatch('refreshDetails', state.id);
    }
};

const mutations = {
    markAsStale (state){
        state.isStale = true;
    },

    markAsNotStale(state) {
        state.isStale = false;
    },

    setGiftCard (state, giftCard) {
        Object.assign(state, giftCard);

        state.isStale = false;
    },

    setRedeemResult(state, result: RedeemResponse){
        state.redeemResult = result;
    },

    setReasonAdded(state){
        if(!state.redeemResult){
            return;
        }

        state.redeemResult.reasonAlreadyAdded = true;
    },

    reset(state){
        Object.assign(state, getDefaultState());
    }
}

const getters = {
    defaultState (){
        return getDefaultState();
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
} as unknown as Module<any, any>
