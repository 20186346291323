
    import { defineComponent } from 'vue';
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import useVuelidate from "@vuelidate/core";
    import { LoginRequest } from "@/api/login";
    import { email } from "@vuelidate/validators";

    export default defineComponent(
        {
            props: {
                email: {
                    type: String,
                    required: false,
                    defaultValue: ''
                }
            },
            data() {
                return {
                    emailAddress: this.email,
                    submitting: false
                };
            },
            components: {
                'submit-button': SubmitButton,
                'redeem-header': Header
            },
            setup() {
                return { v$: useVuelidate() }
            },
            validations: {
                emailAddress: {
                    valid: function (value) {
                        return !!value;
                    },
                    email
                }
            },
            methods: {
                async submit(e) {
                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    this.submitting = true;

                    try {
                        await this.$store.dispatch('user/startPinReset', this.emailAddress);
                    } finally {
                        this.submitting = false;
                    }
                }
            }
        });
