import Noty from "noty";
import Options = Noty.Options;
import {format, toDate, toZonedTime} from "date-fns-tz";

export default class Helpers{
    static emptyGuid = "00000000-0000-0000-0000-000000000000";

    static formatCurrency (value: Number, currency: string, language, minimumFractionDigits = 0, maximumFractionDigits = 2) {
        if (value == null) {
            return '';
        }

        if (parseFloat(value.toFixed(2)) != parseFloat(value.toFixed(0))) {
            minimumFractionDigits = 2;
        }

        return value.toLocaleString(language,
            {
                style: "currency",
                currency: currency,
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: maximumFractionDigits
            }
        );
    }

    static formatDate(value: Date, liveUtcNow: Date, timeZone: string) {
        if (!value) {
            return "";
        }

        const utcValue = toZonedTime(toDate(value, { timeZone: "UTC" }), timeZone);

        if (utcValue.getUTCFullYear() === new Date(liveUtcNow).getUTCFullYear()) {
            return format(utcValue, `MMM do`, { timeZone: timeZone });
        }

        return format(utcValue, `MMM do yyyy`, { timeZone: timeZone });
    }

    static formatDateTime(value: Date, liveUtcNow: Date, timeZone: string = "UTC", language: string = "en-US") {
        if (!value) {
            return "";
        }

        const utcValue = toZonedTime(toDate(value, { timeZone: "UTC" }), timeZone);

        let timeFormat = "p";

        if (Helpers.localeUses24HourTime(language)) {
            timeFormat = "HH:mm"
        }

        if (utcValue.getUTCFullYear() === new Date(liveUtcNow).getUTCFullYear()) {
            return format(utcValue, `MMM do ${timeFormat}`, { timeZone: timeZone });
        }

        return format(utcValue, `MMM do yyyy ${timeFormat}`, { timeZone: timeZone });
    }

    static localeUses24HourTime(langCode) {
        return new Intl.DateTimeFormat(langCode, {
            hour: 'numeric'
        }).formatToParts(new Date(2020, 0, 1, 13)).find(part => part.type === 'hour').value.length === 2;
    }

    static showSuccessNotification(message: string, title: string = null, queue: string = null) : Noty {
        return this.showNotification({
            text: message,
            type: "success",
            queue: queue
        }, title);
    }

    static showInfoNotification(message: string, title: string = null, queue: string = null) : Noty {
        return this.showNotification({
            text: message,
            type: "information",
            queue: queue
        }, title);
    }

    static showWarningNotification(message: string, title: string = null, queue: string = null) : Noty {
        return this.showNotification({
            text: message,
            type: "warning",
            timeout: 7000,
            queue: queue
        }, title);
    }

    static showErrorNotification(message: string, title: string = null, queue: string = null) : Noty {
        return this.showNotification({
            text: message,
            type: "error",
            timeout: 0,
            queue: queue
        }, title);
    }

    static showNotification(options: string | Options, title: string = null) : Noty {
        let defaultOptions = {
            text: "",
            type: "success",
            theme: 'bootstrap-v4',
            timeout: 3000,
            closeWith: ['click', 'button']
        } as Options;

        if (typeof options === "object") {
            Object.assign(defaultOptions, options);
        }

        if(!defaultOptions.text && title){
            defaultOptions.text = title;
            title = "";
        }

        if (typeof options === "string") {
            defaultOptions.text = options as string;
        }

        if (title) {
            defaultOptions.text = `<div>${title}</div>
                                   <div class="font-normal mt-1 text-gray-500">${defaultOptions.text}</div>`;
        }

        let noty = new Noty(defaultOptions);
        noty.show();

        return noty;
    }

    static showErrorNotificationFromFetchRejection(rejectReason: FetchRejectReason) : Noty {
        let message = `An unknown error occurred (${rejectReason.responseStatus}), please try again`;

        if (rejectReason.responseContent) {
            message = rejectReason.errorMessage;

            if (!message) {
                message = rejectReason.responseContent;
            }

            if (!message) {
                message = "We had an error, please try again";
            }
        }

        return this.showErrorNotification(message);
    }


    static clearNotifications(queueName: string = null){
        Noty.closeAll(queueName);
    }

    static deleteCookie(name: string): void {
        let date = new Date();
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 *1000));
        document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/; domain=.giftup.app";
    }

    static async sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    static setAppCompanyId(id: string) {

        try {
            // @ts-ignore
            if (typeof (window.GiftUpApp) !== "undefined" && window.GiftUpApp.PassData) {
                // @ts-ignore
                window.GiftUpApp.PassData(id);
            }
            // @ts-ignore
            window.webkit.messageHandlers.GiftUpApp.postMessage(`companyId:${id}`);
        }
        catch(e){}
    }

    static setAppPageMounted(){
        try {
            // @ts-ignore
            if (typeof (window.GiftUpApp) !== "undefined" && window.GiftUpApp.PageMounted) {
                // @ts-ignore
                window.GiftUpApp.PageMounted();
            }
            // @ts-ignore
            window.webkit.messageHandlers.GiftUpApp.postMessage(`command:pageMounted`);
        }
        catch(e){}
    }
}

export interface FetchRejectReason {
    responseStatus: number,
    responseContent: string,
    errorMessage: string
}
