import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "text-gray-700 pb-2 mt-4"
}
const _hoisted_4 = {
  key: 3,
  class: "text-gray-700 pb-2 mt-4"
}
const _hoisted_5 = {
  key: 4,
  class: "mb-8"
}
const _hoisted_6 = {
  key: 5,
  class: "mb-8"
}
const _hoisted_7 = { class: "text-xs text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_no_network_icon = _resolveComponent("no-network-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.online)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: `${_ctx.publicPath}img/icons/search-fail.svg`,
          class: "opacity-30 w-1/3 mx-auto"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createBlock(_component_no_network_icon, { key: 1 })),
    (_ctx.online)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Page not found"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_4, "No internet connection")),
    (!_ctx.online)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Please ensure you have a working internet connection "))
      : (_openBlock(), _createElementBlock("p", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.route), 1)
        ])),
    _createElementVNode("button", {
      class: "mr-2",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
    }, "Try again"),
    _createElementVNode("button", {
      class: "bg-primary",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
    }, "Back to home")
  ]))
}