
    import { defineComponent } from "vue"

    export default defineComponent(
        {
            name: "alert",
            props: {
                type: {
                    type: String,
                    required: false,
                    default: "error"
                },
                message: {
                    type: String,
                    required: true
                },
                title: {
                    type: String,
                    required: false
                },
                showAction: {
                    type: Boolean,
                    required: false
                },
                actionTitle: {
                    type: String,
                    required: false
                }
            },
            emits: ['actionClicked'],
            computed: {
                standardClasses() {
                    let classes = "";

                    if (!this.$attrs.class || this.$attrs.class.indexOf("rounded") < 0) {
                        classes += "rounded-md ";
                    }
                    if (!this.$attrs.class || this.$attrs.class.indexOf("p-") < 0) {
                        classes += "p-4 ";
                    }

                    return classes;
                },
                bgClass() {
                    if (this.type == "error") {
                        return "bg-red-50";
                    }
                    if (this.type == "warning") {
                        return "bg-yellow-50";
                    }
                    if (this.type == "success") {
                        return "bg-green-50";
                    }
                    if (this.type == "info") {
                        return "bg-blue-50";
                    }

                    return "";
                },
                svgClass() {
                    if (this.type == "error") {
                        return "text-red-400";
                    }
                    if (this.type == "warning") {
                        return "text-yellow-400";
                    }
                    if (this.type == "success") {
                        return "text-green-400";
                    }
                    if (this.type == "info") {
                        return "text-blue-400";
                    }

                    return "";
                },
                titleTextClass() {
                    if (this.type == "error") {
                        return "text-red-800";
                    }
                    if (this.type == "warning") {
                        return "text-yellow-800";
                    }
                    if (this.type == "success") {
                        return "text-green-800";
                    }
                    if (this.type == "info") {
                        return "text-blue-800";
                    }

                    return "";
                },
                messageTextClass() {
                    if (this.type == "error") {
                        return "text-red-700";
                    }
                    if (this.type == "warning") {
                        return "text-yellow-700";
                    }
                    if (this.type == "success") {
                        return "text-green-700";
                    }
                    if (this.type == "info") {
                        return "text-blue-700";
                    }

                    return "";
                }
            },
            methods: {
                actionClicked() {
                    this.$emit('actionClicked')
                }
            }
        });
