import { BaseApi } from "@/api/baseApi";
import {LoginResponse} from "@/api/login";

export default class SwitchApi extends BaseApi {
    static async switchLocation(locationId: string): Promise<LoginResponse> {
        return this.post(`switch-location/${locationId}`);
    }

    static async switchCompany(companyId: string): Promise<LoginResponse> {
        return this.post(`switch-company/${companyId}`);
    }
}
