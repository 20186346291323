
    import { defineComponent } from 'vue';
    import useVuelidate from "@vuelidate/core";
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import LocationPicker from "@/components/LocationPicker.vue";

    export default defineComponent(
        {
            name: 'ChooseLocation',
            components: {
                'submit-button': SubmitButton,
                'location-picker': LocationPicker,
                'redeem-header': Header
            },
            setup() {
                return { v$: useVuelidate() };
            },
            data() {
                return {
                    locationId: '',
                    submitting: false
                };
            },
            validations: {
                locationId: {
                    valid: function(value) {
                        return !!value;
                    }
                }
            },
            computed: {
                user(){
                    return this.$store.state.user;
                }
            },
            methods: {
                async setLocation(){
                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    if(!this.locationId){
                        return;
                    }

                    this.submitting = true;

                    await this.$store.dispatch('user/switchLocation', this.locationId);

                    this.submitting = false;
                },
            }
        });
