import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_and_scan = _resolveComponent("search-and-scan")!
  const _component_submit_button = _resolveComponent("submit-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_search_and_scan, {
      modelValue: _ctx.query,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
      onSubmit: _ctx.submit
    }, null, 8, ["modelValue", "onSubmit"]),
    _createVNode(_component_submit_button, {
      class: "bg-primary lg block",
      type: "submit",
      id: "submit-button",
      tabindex: "3",
      loading: _ctx.submitting,
      onClick: _ctx.submit
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Find " + _toDisplayString(_ctx.company.singularNomenclature.toLowerCase()), 1)
      ]),
      _: 1
    }, 8, ["loading", "onClick"])
  ]))
}