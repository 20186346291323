import { defineComponent } from "vue"

export default defineComponent({
                              directives: {
                                  focus: {
                                      // directive definition
                                      mounted: function (el) {
                                          el.focus()
                                      }
                                  }
                              },
                          });
