
    import {defineComponent} from 'vue';
    import NoNetworkIcon from "@/components/NoNetworkIcon.vue";

    export default defineComponent(
        {
            data() {
                return {
                    publicPath: process.env.BASE_URL
                }
            },
            components: {
                'no-network-icon': NoNetworkIcon,
            },
            computed: {
                route() {
                    return this.$route.fullPath;
                },
                online() {
                    return this.$store.state.online;
                }
            },
            methods: {
                reload() {
                    window.location.reload();
                },
                goHome() {
                    this.$router.push("/");
                }
            }
        });
