import { BaseApi } from "@/api/baseApi";

export default class DetailsApi extends BaseApi {
    static async details(id: string): Promise<DetailsResponse> {
        return this.get(`details/${id}`);
    }
}

export interface DetailsResponse {
    companyId: string;
    voidedOn: string;
    code: string;
    title: string;
    expiresOn: string;
    validFrom: string;
    redeemedOn: string;
    recipientName: string;
    artworkUrl: string;
    remainingValue: number;
    remainingUnits: number;
    initialUnits: number;
    backingType: string;
    id: string;
    orderMode: string;
    orderId: string;
    currency: string;
    language: string;
    tip: number;
    canBeRedeemed: boolean;
    notYetValid: boolean;
    isVoided: boolean;
    hasExpired: boolean;
    isRedeemed: boolean;
    log: Array<DetailsResponseLog>;
    redemptionLocationIds: Array<string>;
    customFields: Array<DetailsResponseCustomField>;
}

export interface DetailsResponseLog {
    action: string;
    createdOn: string;
    valueChange: number;
    username: string;
    avatarUrl: string;
    id: string;
    reportLedgerTransactionId: string;
    canUndo: boolean;
    initials: string;
}

export interface DetailsResponseCustomField{
    label: string;
    value: any;
}
