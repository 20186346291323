import { Module } from "vuex";
import CompanyApi, { CompanyLoginResponseUser } from "@/api/company";
import Helpers from "@/Helpers";

export interface CompanyState {
    id: string;
    name: string;
    language: string;
    timeZone: string;
    isSuspended: boolean;
    suspendedOn: string;
    logoUrl: string;
    singularNomenclature: string;
    pluralNomenclature: string;
    singularNomenclatureHumanized: string;
    pluralNomenclatureHumanized: string;
    enableScanning: boolean;
    hasCloudbeds: boolean;
    login: { changedCompanyId: boolean; canSignInWithEmail: boolean; users: Array<CompanyLoginResponseUser> };
}

function getDefaultState() : CompanyState {
    return <CompanyState>{
        id: null as string,
        name: null as string,
        language: null as string,
        timeZone: null as string,
        isSuspended: false,
        suspendedOn: null as string,
        logoUrl: null as string,
        singularNomenclature: null as string,
        pluralNomenclature: null as string,
        singularNomenclatureHumanized: null as string,
        pluralNomenclatureHumanized: null as string,
        enableScanning: false,
        hasCloudbeds: false,
        login: {
            users: [] as Array<CompanyLoginResponseUser>,
            canSignInWithEmail: false,
            changedCompanyId: false,
            loaded: false
        }
    };
}

const state = getDefaultState();

const actions = {
    async getDetails({ state, commit, rootState }, companyId: string){
        const response = await CompanyApi.details(companyId);

//        await Helpers.sleep(3000);

        let changedCompanyId = state.id != companyId;

        commit('setCompany', response.company);
        commit('setLogin', { users: response.users, canSignInWithEmail: response.canSignInWithEmail, changedCompanyId});
    },

    async reset({commit}){
        commit('reset');
    }
};

const mutations = {
    setCompany (state: CompanyState, company: CompanyState){
        Object.assign(state, company);

        Helpers.setAppCompanyId(company.id);
    },

    setLogin(state, login){
        state.login = login;
        state.login.loaded = true;
    },

    reset(state){
        Object.assign(state, getDefaultState());
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
} as unknown as Module<any, any>
