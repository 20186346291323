import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/styles/app.css'
import store from './store'
import { HostingEnvironmentFactory } from "@/HostingEnvironmentFactory";

let app = createApp(App);

// Sentry.init({
//                 app,
//                 dsn: "https://da388e45e3de4abbadd3d7b5a8513bf0@o327459.ingest.sentry.io/5849182",
//                 environment: HostingEnvironmentFactory.get().env,
//                 integrations: [
//                     new Integrations.BrowserTracing({
//                                                         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                                                         tracingOrigins: ["dev.giftup.app", /^\//]
//                                                     })
//                 ],
//                 // Set tracesSampleRate to 1.0 to capture 100%
//                 // of transactions for performance monitoring.
//                 // We recommend adjusting this value in production
//                 tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0
//             });

app.use(router)
   .use(store)
   .mount('#app');
