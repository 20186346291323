
    import { defineComponent } from 'vue';
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import useVuelidate from "@vuelidate/core";
    import GiftCardSearchAndScan from "@/components/GiftCardSearchAndScan.vue";
    import GiftCardRemainingBalanceText from "@/components/GiftCardRemainingBalanceText.vue";
    import GiftCardStatusBadge from "@/components/GiftCardStatusBadge.vue";
    import Alert from "@/components/Alert.vue";
    import Avatar from "@/components/Avatar.vue";
    import FocusDirective from "@/mixins/FocusDirective";
    import Helpers, { FetchRejectReason } from "@/Helpers";
    import { AddReasonRequest, RedeemResponse } from "@/api/redeem";
    import store from "@/store";
    import { CompanyState } from "@/store/modules/company";
    import { UserState } from "@/store/modules/user";
    import { GiftCardState } from "@/store/modules/giftCard";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";
    import GiftUpRedeemer from "@/components/Redeemers/GiftUpRedeemer.vue";
    import CloudbedsRedeemer from "@/components/Redeemers/CloudbedsRedeemer.vue";
    import {SearchQuery} from "@/store/modules/search";

    export default defineComponent(
        {
            components: {
                CloudbedsRedeemer,
                'submit-button': SubmitButton,
                'redeem-header': Header,
                'alert': Alert,
                'remaining-balance': GiftCardRemainingBalanceText,
                'redeem-avatar': Avatar,
                'status-badge': GiftCardStatusBadge,
                'search-and-scan': GiftCardSearchAndScan,
                'loading-spinner': LoadingSpinner,
                'gift-up-redeemer': GiftUpRedeemer
            },
            mixins: [
                FocusDirective
            ],
            props: {
                id: String
            },
            setup() {
                return { v$: useVuelidate() }
            },
            async beforeRouteEnter(to) {
                try {
                    await store.dispatch("giftCard/getDetails", to.params.id);
                } catch (ex) {
                    console.error("Routing into details aborted");
                    console.error(ex);
                    return false;
                }
            },
            async beforeRouteUpdate(to) {
                await store.dispatch("giftCard/getDetails", to.params.id);
            },
            created() {
                this.$nextTick(() => {
                    let terms = document.getElementById("terms");

                    if (terms && terms.clientHeight < terms.scrollHeight) {
                        this.termsOverflowing = true;
                    }
                });
            },
            data() {
                return {
                    redemptionMessageTitle: '',
                    redemptionMessageBody: '',
                    termsExpanded: false,
                    logExpanded: false,
                    termsOverflowing: false,
                    maxLogsShownByDefault: 3,
                    undoingRedemption: false,
                    addReasonText: false,
                    addingReason: false,
                    searchingStarted: false,
                    searching: false,
                    reason: '',
                    forceGenericRedeemer: false
                };
            },
            computed: {
                company(): CompanyState {
                    return this.$store.state.company;
                },

                user(): UserState {
                    return this.$store.state.user;
                },

                location() {
                    return this.$store.state.location;
                },

                giftCard(): GiftCardState {
                    return this.$store.state.giftCard;
                },

                redeemResult(): RedeemResponse {
                    return this.giftCard.redeemResult;
                },

                canRedeemToday(): boolean{
                  if(this.giftCard.validOnDays
                      && this.giftCard.validOnDays.length > 0
                      && this.giftCard.validOnDays.length < 7
                      && this.giftCard.validOnDays.indexOf(new Date().getDay()) == -1){
                    return false;
                  }

                  return true;
                },

                query: {
                    get() {
                        return this.$store.state.search.query;
                    },
                    set(value) {
                        this.$store.commit('search/setQuery', SearchQuery.search(value, this.page));
                    }
                },
                page: {
                    get() {
                        return this.$store.state.search.page;
                    },
                    set(value) {
                        this.$store.commit('search/setQuery', SearchQuery.search(this.query, value));
                    }
                },

                showRedeemMessage() {
                    if (!this.redeemResult) {
                        return false;
                    }

                    return this.redeemResult.transactionId;
                },

                testAlertTitle() {
                    return `This is a test ${this.company.singularNomenclature.toLowerCase()}`;
                },
                testAlertMessage() {
                    return `This ${this.company.singularNomenclature.toLowerCase()} was created for testing & training purposes, it should not be accepted if presented by a customer.`;
                },
                expiredAlertMessage() {
                    if (this.giftCard.expiresOn) {
                        let message = `This ${this.company.singularNomenclature.toLowerCase()} expired end of day on ${this.formatDate(this.giftCard.expiresOn)}`;

                        if (this.user.canRedeemExpiredGiftCards) {
                            message += ", but it can be redeemed at your discretion";
                        } else {
                            message += " and cannot be redeemed";
                        }

                        return message;
                    }
                },
                validFromAlertMessage() {
                    if (this.giftCard.validFrom) {
                        let message = `This ${this.company.singularNomenclature.toLowerCase()}
                                       is valid from ${this.formatDate(this.giftCard.validFrom)}`;

                        if (this.user.canRedeemExpiredGiftCards) {
                            message += ", but it can be redeemed at your discretion";
                        } else {
                            message += " and cannot be redeemed";
                        }

                        return message;
                    }
                },
                validOnDaysAlertMessage(){
                    if(this.giftCard.validOnDays && this.giftCard.validOnDays.length > 0 && this.giftCard.validOnDays.length < 7){
                        let message = `This ${this.company.singularNomenclature.toLowerCase()} ${this.user.canRedeemExpiredGiftCards ? 'should' : 'may'} only be redeemed on `;

                        for (const day of this.giftCard.validOnDays) {
                            let dayName = '';
                            switch (day) {
                                case 0:
                                    dayName = 'Sunday';
                                  break;
                                case 1:
                                    dayName = 'Monday';
                                    break;
                                case 2:
                                    dayName = 'Tuesday';
                                    break;
                                case 3:
                                    dayName = 'Wednesday';
                                    break;
                                case 4:
                                    dayName = 'Thursday';
                                    break;
                                case 5:
                                    dayName = 'Friday';
                                    break;
                                case 6:
                                    dayName = 'Saturday';
                                    break;
                            }
                            message += `${dayName}s, `;
                        }

                        let lastComma = message.lastIndexOf(',');

                        let arr = message.split('');
                        arr[lastComma] = '';
                        message = arr.join('');

                        lastComma = message.lastIndexOf(',');
                        arr = message.split('');
                        arr[lastComma] = " &";
                        message = arr.join('').trim();

                        if(this.user.canRedeemExpiredGiftCards){
                            message += ", but you may continue at your discretion";
                        }

                        return message;
                    }
                },
                redeemLocationRestrictionMessage() {
                    if (this.giftCard.redemptionLocationIds
                        && this.giftCard.redemptionLocationIds.length > 0) {

                        if (!this.giftCard.redemptionLocationIds.find(id => id == this.location.id)
                            || this.location.id == null) {

                            let message = "";
                            if (this.user.canRedeemLocationRestrictedGiftCards) {
                                message = `You should not redeem this ${this.company.singularNomenclature.toLowerCase()} at`;

                                if (this.location && this.location.name) {
                                    message += ` "${this.location.name}"`;
                                } else {
                                    message += " your selected location";
                                }
                            } else {
                                message = `You cannot redeem this ${this.company.singularNomenclature.toLowerCase()} at`;

                                if (this.location && this.location.name) {
                                    message += ` "${this.location.name}"`;
                                } else {
                                    message += " your selected location";
                                }
                            }

                            return message;
                        }
                    }

                    return "";
                },
                canPartiallyRedeemGiftCard() {
                    return this.giftCard.canBeRedeemed &&
                           (this.giftCard.backingType.toLowerCase() == "currency" || this.giftCard.remainingUnits > 1);
                },
                backRoute() {
                    if (this.query) {
                        return { name: 'Search', query: { query: this.query, page: this.page }, hash: "#GC_" + this.giftCard.id }
                    }

                    return { name: 'Redeem', params: { companyId: this.company.id } }
                },
                now() {
                    return this.$store.getters.liveUtcNow;
                }
            },
            validations: {
                reason: {
                    valid: function (value) {
                        if (!this.addingReason) {
                            return true;
                        }

                        return !!value;
                    }
                },
                query: {
                    valid: function (value) {
                        if (!this.searchingStarted) {
                            return true;
                        }

                        return !!value && value.length >= 3;
                    }
                }
            },
            provide() {
                return {
                    "$parent.v$": this.v$
                };
            },
            methods: {

                redeemStarted(){
                    this.searchingStarted = false;
                },

                redeemCompleted(){
                    if (this.isInViewport(this.$refs.searchForNextGiftCard) == false) {
                        this.$refs.searchForNextGiftCard.scrollIntoView();
                    }
                },

                formatCurrency(value: Number) {
                    return Helpers.formatCurrency(value, this.giftCard.currency, this.giftCard.language, 0, 4);
                },

                scrollTo(ref, extraOffset) {
                    try {
                        let bodyRect = document.body.getBoundingClientRect(),
                            elemRect = this.$refs[ref].getBoundingClientRect(),
                            offset = elemRect.top - bodyRect.top;

                        window.scrollTo(0, offset - extraOffset);
                    } catch (e) {
                    }
                },

                isInViewport(elem) {
                    if (elem) {
                        const bounding = elem.getBoundingClientRect();
                        return (
                            bounding.top >= 0 &&
                            bounding.left >= 0 &&
                            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
                        );
                    }

                    return null;
                },

                showedMoreLogs() {
                    this.scrollTo("logs", 0);
                },

                async undoRedemption() {
                    if (!this.redeemResult) {
                        return;
                    }

                    this.undoingRedemption = true;

                    try {
                        await this.$store.dispatch('giftCard/undoRedeem', this.redeemResult.transactionId);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }

                    this.undoingRedemption = false;
                },

                async addReason() {
                    if (!this.reason) {
                        Helpers.showWarningNotification("Please add a reason");
                        return;
                    }

                    this.addingReason = true;

                    try {
                        await this.$store.dispatch('giftCard/addReason',
                                                   AddReasonRequest.forTransaction(this.redeemResult.transactionId,
                                                                                   this.reason));
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }

                    this.addingReason = false;
                    this.addReasonText = false;
                    this.reason = "";
                },

                async invokeSearch() {
                    this.searchingStarted = true;

                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        this.searchingStarted = false;
                        this.searching = false;
                        return;
                    }

                    this.searching = true;

                    await this.$store.dispatch("search/search", SearchQuery.search(this.query, 1));

                    this.searching = false;

                    this.v$.$reset();
                },

                formatDateTime(value) {
                    return Helpers.formatDateTime(value, this.$store.getters.liveUtcNow, this.company.timeZone, this.company.language);
                },

                formatDate(value) {
                    return Helpers.formatDate(value, this.$store.getters.liveUtcNow, this.company.timeZone);
                }

            }
        });

    class GiftCardLog {
        id: string;
        action: string;
        createdOn: string;
        who: string;
        avatarUrl: string;
        initials: string;
        valueChange: number;
        reportLedgerTransactionId: string;
        canUndo: boolean;
    }
