import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "locationpicker" }
const _hoisted_2 = ["name"]
const _hoisted_3 = {
  key: 0,
  class: "field-validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_picker = _resolveComponent("select-picker")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "hidden",
      name: _ctx.formName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLocation) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.selectedLocation]
    ]),
    _createVNode(_component_select_picker, {
      title: "Select your location…",
      label: _ctx.showLabel ? 'Location' : '',
      modelValue: _ctx.selectedLocation,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedLocation) = $event)),
      list: _ctx.locations,
      tabindex: _ctx.tabindex,
      "value-prop": "id",
      "text-prop": "name",
      "option-renderer": _ctx.optionRender
    }, null, 8, ["label", "modelValue", "list", "tabindex", "option-renderer"]),
    (_ctx.showValidationError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Please select your location"))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, _ctx.locations.length > 0]
  ])
}