import { BaseApi } from "@/api/baseApi";

export default class CompanyApi extends BaseApi {
    static async details(companyId: string): Promise<CompanyLoginResponse> {
        return this.get(`company/${companyId}`);
    }
}

export interface CompanyLoginResponse {
    companyId: string;
    company: CompanyDetails;
    canSignInWithEmail: boolean;
    users: Array<CompanyLoginResponseUser>;
}

export interface CompanyLoginResponseUser {
    id: string;
    name: string;
    initials: string;
    displayName: string;
    hasPinNumber: boolean;
    avatarUrl: string;
    isHiddenInRedeemApp: boolean;
    canLogin: boolean;
}

export interface CompanyDetails {
    id: string;
    name: string;
    language: string;
    timeZone: string;
    isSuspended: boolean;
    suspendedOn: string;
    logoUrl: string;
    enableScanning: boolean;
    singularNomenclature: string;
    pluralNomenclature: string;
    singularNomenclatureHumanized: string;
    pluralNomenclatureHumanized: string;
    hasCloudbeds: boolean;
}
