import { BaseApi } from "@/api/baseApi";

export default class PinResetApi extends BaseApi {
    static async startPinReset(email: string, companyId: string): Promise<void> {
        return this.post(`pin-reset/start`, { email, companyId });
    }

    static async verifyPinToken(request: VerifyPinRequest): Promise<VerifyPinResetResponse>{
        return this.post(`pin-reset/verify`, request);
    }

    static async completePinReset(request: ChangePinRequest): Promise<void> {
        return this.post(`pin-reset/complete`, request);
    }
}

export class VerifyPinRequest {
    token: string;
    userId: string;

    static create(token: string, userId: string): VerifyPinRequest {
        return { token: token, userId } as VerifyPinRequest;
    }
}

export class ChangePinRequest {
    token: string;
    newPin: string;
    userId: string;

    static create(token: string, newPin: string, userId: string): ChangePinRequest {
        return { token: token, newPin, userId } as ChangePinRequest;
    }
}

export class VerifyPinResetResponse {
    userDisplayName: string;
    tokenValid: boolean;
}
