
    import { defineComponent } from 'vue';
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import useVuelidate from "@vuelidate/core";
    import store from "@/store";
    import { ChangePinRequest, VerifyPinRequest } from "@/api/pinReset";
    import FocusDirective from "@/mixins/FocusDirective";

    export default defineComponent(
        {
            data() {
                return {
                    newPin: '',
                    confirmNewPin: '',
                    submitting: false
                };
            },
            components: {
                'submit-button': SubmitButton,
                'redeem-header': Header
            },
            props: {
                token: {
                    type: String,
                    required: true
                },
                userId: {
                    type: String,
                    required: true
                },
                companyId: {
                    type: String,
                    required: false
                }
            },
            setup() {
                return { v$: useVuelidate() }
            },
            mixins: [
                FocusDirective
            ],
            async beforeRouteEnter(to){
                if (to.query.companyId) {
                    await store.dispatch("company/getDetails", to.query.companyId);
                }

                await store.dispatch("user/verifyPinReset", VerifyPinRequest.create(to.query.token as string, to.query.userId as string));
            },
            validations: {
                newPin: {
                    valid: function (value: number) {
                        if (!value) {
                            return false;
                        }

                        return value.toString().length >= 4;
                    }
                },
                confirmNewPin: {
                    valid: function (value: number) {
                        if (!value) {
                            return false;
                        }

                        return value.toString().length >= 4 && value == this.newPin;
                    }
                }
            },
            computed: {
                user(){
                    return this.$store.state.user;
                }
            },
            methods: {
                async submit(e) {
                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    this.submitting = true;

                    await this.$store.dispatch("user/completePinReset", ChangePinRequest.create(this.token, this.newPin, this.userId));

                    this.submitting = false;
                }
            }
        });
