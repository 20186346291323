import { createStore } from "vuex";
import VuexPersist from 'vuex-persist';
import user from "@/store/modules/user";
import company from "@/store/modules/company";
import location from "@/store/modules/location";
import search from "@/store/modules/search";
import giftCard from "@/store/modules/giftCard";
import cloudbeds from "@/store/modules/cloudbeds";
import { BaseApi } from "@/api/baseApi";
import { toDate } from "date-fns-tz";
import { addSeconds, differenceInSeconds } from "date-fns";
import router from "@/router";

let debug = process.env.NODE_ENV !== 'production'

debug = false;

const vuexLocalStorage = new VuexPersist(
    {
        strictMode: debug,
        key: 'vuex', // The key to store the state on in the storage provider.
        storage: window.localStorage, // or window.sessionStorage or localForage
        // Function that passes the state and returns the state with only the objects you want to store.
        reducer: (state: any) => {
            let company = JSON.parse(JSON.stringify(state.company));

            delete company.login;

            let cloudbeds = { selectedHotelId: state.cloudbeds.selectedHotelId};

            return ({
                company: company,
                location: state.location,
                user: state.user,
                cloudbeds
            });
        },
        // Function that passes a mutation and lets you decide if it should update the state in localStorage.
        // filter: mutation => (true)
    });

const vuexSessionStorage = new VuexPersist(
    {
        strictMode: debug,
        key: 'vuex', // The key to store the state on in the storage provider.
        storage: window.sessionStorage, // or window.sessionStorage or localForage
        // Function that passes the state and returns the state with only the objects you want to store.
        reducer: (state: any) => {
            return ({
                giftCard: state.giftCard,
            });
        },
        // Function that passes a mutation and lets you decide if it should update the state in localStorage.
        // filter: mutation => (true)
    });

let store = createStore(
    {
        strict: debug,
        plugins: [vuexLocalStorage.plugin, vuexSessionStorage.plugin],
        modules: {
            company,
            location,
            search,
            user,
            giftCard,
            cloudbeds
        },
        state: {
            latestServerUtcNow: null,
            clockDiff: 0,
            allowGenericHomeRedirect: true,
            lastNavigationRoute: null,
            online: true,
            intercepted401Action: null as Intercepted401Action
        },
        actions: {
            setServerDate({ commit }, date: string){
                commit('setServerDate', date);
            },
            async forceGenericHome( { dispatch, commit }){
                commit('setAllowGenericHomeRedirect', false);

                await router.push({ name: "GenericLogin" });

                await dispatch("user/reset");
                await dispatch("company/reset");

                commit('setAllowGenericHomeRedirect', true);
            },
            async dispatchIntercepted401Action( { dispatch, commit, state, rootState }) {
                const action = state.intercepted401Action as Intercepted401Action;

                if (!action) {
                    if (rootState.lastNavigationRoute) {
                        // console.log(rootState.lastNavigationRoute.fullPath);
                        await router.push(rootState.lastNavigationRoute);

                        commit('setLastNavigationRoute', null, { root: true });
                    }

                    return;
                }

                try {
                    await dispatch(action.action, action.payload);
                }
                catch (ex) {
                    console.error(ex);
                }
                finally {
                    commit('setIntercepted401Action', null);
                }
            }
        },
        mutations: {
            setLastNavigationRoute(state, route){
                state.lastNavigationRoute = route;
            },
            setAllowGenericHomeRedirect(state, value){
                state.allowGenericHomeRedirect = value;
            },
            setServerDate(state, date){

                if(!date){
                    return;
                }

                const utcDateTimeNowClock = new Date(new Date().toUTCString());
                state.latestServerUtcNow = toDate(new Date(date), { timeZone: 'UTC' });
                state.clockDiff = differenceInSeconds(state.latestServerUtcNow, utcDateTimeNowClock);
            },
            setIntercepted401Action(state, action: Intercepted401Action) {
                state.intercepted401Action = action;
            }
        },
        getters: {
            liveUtcNow(state) {
                return addSeconds(new Date(new Date().toUTCString()), state.clockDiff)
            },
            state(state){
                return state;
            }
        }
    }
);

window.addEventListener("offline", _ => {
    store.state.online = false;
});

window.addEventListener("online", _ => {
    store.state.online = true;
});

BaseApi.setStore(store);

export default store;

export interface Intercepted401Action {
    action: string,
    payload: any
}
