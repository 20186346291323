import { BaseApi } from "@/api/baseApi";

export default class RedeemApi extends BaseApi {
    static async redeem(request: RedeemRequest): Promise<RedeemResponse> {
        if (request.type == 'partial') {
            return this.post(`redeem-partially/${request.id}`, {value: request.value});
        }

        return this.post(`redeem-fully/${request.id}`);
    }

    static async undo(transactionId: string): Promise<string> {
        return this.post(`undo/${transactionId}`, {});
    }

    static async addReason(request: AddReasonRequest): Promise<string> {
        return this.post(`add-reason/${request.transactionId}`, { reason: request.reason });
    }
}

export class RedeemRequest {
    id: string;
    value: number;
    type: string;

    static partially(id: string, value: number): RedeemRequest {
        return { id, value, type: 'partial' } as RedeemRequest;
    }

    static fully(id: string) {
        return { id, type: 'full' } as RedeemRequest;
    }
}

export class AddReasonRequest {
    transactionId: string;
    reason: string;

    static forTransaction(transactionId: string, reason: string): AddReasonRequest{
        return { transactionId, reason } as AddReasonRequest;
    }
}

export interface RedeemResponse {
    transactionId: string;
    reasonAlreadyAdded: boolean;
    canBeReversed: boolean;
    canBeRedeemed: boolean;
    valueRemoved: string;
    remainingValue: string;
    messageTitle: string;
    messageBody: string;
}
