
import * as Ladda from 'ladda';
import { defineComponent } from "vue";

export default defineComponent({
    name: 'SubmitButton',
    props: {
        // use vue props validation to make sure "data-style" is given. (ladda need it)
        'dataStyle': {
            type: String,
            default: 'expand-left'
        },
        spinnerColor: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false
        },
        type: {
            type: String,
            required: false,
            default: 'button'
        },
        spinnerSize: {
            type: String,
            required: false,
            default: "28"
        },
        loading: {
            type: Boolean,
            required: true
        },
        progress: {
            validator: function(progress: number) {
                return progress >= 0 && progress <= 1;
            },
            default: 0
        },
        areYouSure: {
            type: Boolean,
            required: false
        }
    },
    emits: ['click'],
    data: function () {
        return {
            dataSpinnerColor: "",
            showAreYouSureMessage: false
        }
    },
    watch: {
        loading: function(loading) {
            loading ? this.ladda.start() : this.ladda.stop();
        },
        progress: function(progress) {
            this.ladda.setProgress(progress);
        }
    },
    methods: {
        handleClick: function(e) {
            if (!this.areYouSure || this.showAreYouSureMessage) {
                this.$emit('click', e);
                return;
            }

            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            this.showAreYouSureMessage = true;
        }
    },
    created(){
        if (this.spinnerColor) {
            this.dataSpinnerColor = this.spinnerColor;

            if (this.spinnerColor.indexOf("red") >= 0) {
                this.dataSpinnerColor = "#FCA5A5";
            }
            if (this.spinnerColor.indexOf("orange") >= 0) {
                this.dataSpinnerColor = "#FCD34D";
            }
            if (this.spinnerColor.indexOf("blue") >= 0) {
                this.dataSpinnerColor = "#A5B4FC";
            }
            if (this.spinnerColor.indexOf("green") >= 0) {
                this.dataSpinnerColor = "#10B981";
            }
            if (this.spinnerColor.indexOf("dark") >= 0) {
                this.dataSpinnerColor = "#374151";
            }
        }
    },
    mounted() {
        this.ladda = Ladda.create(this.$refs.ladda);
        this.loading ? this.ladda.start() : this.ladda.stop();
    },
    beforeUnmount() {
        this.ladda.remove();
        delete this.ladda;
    }
});
