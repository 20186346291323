
    import { defineComponent } from "vue";
    import SubmitButton from "@/components/SubmitButton.vue";
    import Alert from "@/components/Alert.vue";
    import useVuelidate from "@vuelidate/core";
    import FocusDirective from "@/mixins/FocusDirective";
    import Helpers, { FetchRejectReason } from "@/Helpers";
    import SelectPicker from "@/components/SelectPicker.vue";
    import GiftUpRedeemer from "@/components/Redeemers/GiftUpRedeemer.vue";
    import { CloudbedsRedeemRequest, CloudbedsRefreshReservationRequest, SearchRequest } from "@/api/cloudbeds";
    import {CloudbedsState, HotelState, SearchResultState} from "@/store/modules/cloudbeds";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";
    import { GiftCardState } from "@/store/modules/giftCard";
    import Avatar from "@/components/Avatar.vue";

    export default defineComponent(
        {
            components: {
                'redeem-avatar': Avatar,
                'submit-button': SubmitButton,
                'alert': Alert,
                'select-picker': SelectPicker,
                'gift-up-redeemer': GiftUpRedeemer,
                'loading-spinner': LoadingSpinner
            },
            setup() {
                return { v$: useVuelidate() }
            },
            mixins: [
                FocusDirective
            ],
            props: {
                canPartiallyRedeem: Boolean,
                giftCard: Object as () => GiftCardState
            },
            emits: ['redeemStarted', 'redeemCompleted', 'redeemManually'],
            data() {
                return {
                    isReservationSelected: false,
                    redeemingFully: false,
                    redeemingPartiallyStarted: false,
                    redeemingPartially: false,
                    openRedeemingPartiallyPanel: false,
                    redeemingTooMuch: false,
                    notRedeemingWholeUnits: false,
                    partialRedeemValue: '',
                    roomName: '',
                    publicPath: process.env.BASE_URL,
                    showAllResults: false,
                    showResultsLimit: 5
                }
            },
            async created() {
                await this.$store.dispatch('cloudbeds/resetSearch');
                await this.$store.dispatch('cloudbeds/listHotels');
            },
            computed: {
                state() : CloudbedsState {
                    return this.$store.state.cloudbeds;
                },
                newBalance() {
                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        const remove = Number(this.partialRedeemValue);

                        let newRemainingValue = this.giftCard.remainingValue;

                        if (remove < 0) {
                            this.redeemingTooMuch = true;
                            return this.formatCurrency(newRemainingValue);
                        }

                        if (remove > 0) {
                            newRemainingValue -= remove;
                        }

                        if (newRemainingValue < 0) {
                            this.redeemingTooMuch = true;
                            return this.formatCurrency(0);
                        }

                        this.redeemingTooMuch = false;
                        return this.formatCurrency(newRemainingValue);
                    }
                },
                newReservationBalance() {
                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        const remove = Number(this.partialRedeemValue);

                        let newRemainingValue = this.selectedReservation.balance;

                        if (remove > 0) {
                            newRemainingValue -= remove;
                        }

                        return this.formatCurrency(newRemainingValue);
                    }
                },
                hasRedeemValueError() {
                    return this.redeemingTooMuch || this.notRedeemingWholeUnits;
                },

                redeemingTooMuchAlertMessage() {
                    const remove = Number(this.partialRedeemValue);

                    if (remove < 0) {
                        return `You cannot redeem a negative amount`;
                    }

                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        return `You cannot redeem more than ${this.formatCurrency(this.giftCard.remainingValue)}`;
                    }
                    else if (this.giftCard.backingType.toLowerCase() === "units") {
                        if (this.remainingUnits === 1) {
                            return `You cannot redeem more than ${this.remainingUnits} unit`;
                        }

                        return `You cannot redeem more than ${this.remainingUnits} units`;
                    }

                    return "";
                },

                redeemPartiallyPlaceholder() {
                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        return Helpers.formatCurrency(0, this.giftCard.currency, this.giftCard.language, 0, 0);
                    }

                    return "0";
                },
                hotels() {
                    return this.state.hotels;
                },
                tokenExpired(){
                    return this.state.tokenExpired
                },
                selectedHotelId: {
                    get: function () {
                        return this.state.selectedHotelId;
                    },
                    set: function (hotelId: string) {
                        this.$store.dispatch('cloudbeds/selectHotel', hotelId);
                    }
                },
                selectedHotel() : HotelState {
                    return this.$store.getters["cloudbeds/selectedHotel"];
                },
                searchResults() : SearchResultState[] {
                    return this.state.searchResults;
                },
                selectedReservation(): SearchResultState {
                    return this.state.selectedReservation;
                },
                companyId(){
                    return this.$store.state.company.id;
                },
                singularNomenclature(){
                    return this.$store.state.company.singularNomenclature.toLowerCase();
                },
                isHotelsListLoaded(){
                    return this.state.isHotelsListLoaded;
                },
                isSearchResultStale(): boolean{
                    return this.state.isSelectedReservationStale;
                },
                showSearch(): boolean {
                    return !this.selectedReservation && !this.tokenExpired;
                }
            },

            validations: {
                partialRedeemValue: {
                    valid: function (value) {
                        if (!this.redeemingPartiallyStarted) {
                            return true;
                        }

                        const remove = Number(value);

                        if (remove <= 0) {
                            return false;
                        }

                        if (this.giftCard.backingType.toLowerCase() === "currency") {
                            if (this.giftCard.remainingValue - remove < 0) {
                                return false;
                            }

                        }
                        else if (this.giftCard.backingType.toLowerCase() === "units") {
                            if (this.remainingUnits - Math.trunc(remove) < 0) {
                                return false;
                            }
                        }

                        return value > 0;
                    }
                }
            },
            methods: {
                showAllSearchResults() {
                    this.showAllResults = true;
                    this.showResultsLimit = 9999;
                },

                redeemStarted(){
                    this.$emit('redeemStarted');
                },

                redeemCompleted(){
                    this.$emit('redeemCompleted');
                },

                formatCurrency(value: Number) {
                    return Helpers.formatCurrency(value, this.giftCard.currency, this.giftCard.language, 0, 4);
                },

                async resetSearch(){
                    await this.$store.dispatch('cloudbeds/resetSearch');
                },

                async resetHotel(){
                    await this.$store.dispatch('cloudbeds/resetHotel');
                },

                async searchForReservation(){
                    if(!this.roomName || this.roomName.length == 0){
                        return;
                    }

                    let searchRequest = SearchRequest.search(this.selectedHotelId, this.roomName);

                    try {
                        await this.$store.dispatch('cloudbeds/search', searchRequest);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }
                },

                async redeemFully() {
                    this.redeemingFully = true;

                    let redeemRequest = CloudbedsRedeemRequest.fully(this.giftCard.id, this.selectedReservation.reservationId);

                    try {
                        await this.$store.dispatch('cloudbeds/redeem', redeemRequest);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }

                    this.redeemingFully = false;
                    this.v$.$reset();

                    this.$emit("redeemCompleted");
                },

                async redeemPartially() {
                    this.$emit("redeemStarted");

                    this.redeemingPartiallyStarted = true;

                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        this.redeemingPartiallyStarted = false;
                        this.redeemPartiallyInputFocus();
                        return;
                    }

                    this.redeemingPartially = true;

                    let redeemRequest = CloudbedsRedeemRequest.partially(this.giftCard.id, this.selectedHotelId, this.selectedReservation.reservationId, this.partialRedeemValue);
                    this.partialRedeemValue = '';
                    this.v$.$reset();

                    try {
                        await this.$store.dispatch('cloudbeds/redeem', redeemRequest);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }

                    this.redeemingPartially = false;
                    this.openRedeemingPartiallyPanel = false;
                    this.redeemingPartiallyStarted = false;
                    this.v$.$reset();

                    this.$emit("redeemCompleted");
                },

                async selectReservation(reservation: SearchResultState){
                    let refreshRequest =  new CloudbedsRefreshReservationRequest();
                    refreshRequest.reservationId = reservation.reservationId;
                    refreshRequest.propertyId = reservation.propertyId;

                    await this.$store.dispatch('cloudbeds/refreshReservation', refreshRequest);
                },

                redeemManually(){
                    this.$emit("redeemManually");
                },

                redeemPartiallyInputFocus() {
                    document.getElementById("RedeemPartiallyInput").focus();
                },
            }
        });
