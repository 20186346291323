
    import { defineComponent } from "vue";
    import SubmitButton from "@/components/SubmitButton.vue";
    import Alert from "@/components/Alert.vue";
    import { RedeemRequest } from "@/api/redeem";
    import useVuelidate from "@vuelidate/core";
    import FocusDirective from "@/mixins/FocusDirective";
    import Helpers, { FetchRejectReason } from "@/Helpers";

    export default defineComponent(
        {
            components: {
                'submit-button': SubmitButton,
                'alert': Alert
            },
            setup() {
                return { v$: useVuelidate() }
            },
            mixins: [
                FocusDirective
            ],
            props: {
                canPartiallyRedeem: Boolean,
                giftCard: Object
            },
            emits: ['redeemStarted', 'redeemCompleted'],
            data() {
                return {
                    redeemingFully: false,
                    redeemingPartiallyStarted: false,
                    redeemingPartially: false,
                    openRedeemingPartiallyPanel: false,
                    redeemingTooMuch: false,
                    notRedeemingWholeUnits: false,
                    partialRedeemValue: ''
                }
            },
            computed: {
                isUnitBacked() {
                    return this.giftCard.backingType.toLowerCase() === "units"
                },
                partiallyRedeemText() {
                    return (this.isUnitBacked ? "Redeem multiple" : "Partially redeem");
                },
                partiallyOrFullyRedeemText() {
                    return (Number(this.partialRedeemValue) == this.giftCard.remainingValue
                        || Number(this.partialRedeemValue) == this.giftCard.remainingUnits ? "Redeem in full" : "Partially redeem");
                },
                newBalance() {
                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        const remove = Number(this.partialRedeemValue);

                        let newRemainingValue = this.giftCard.remainingValue;

                        if (remove < 0) {
                            this.redeemingTooMuch = true;
                            return this.formatCurrency(newRemainingValue);
                        }

                        if (remove > 0) {
                            newRemainingValue -= remove;
                        }

                        if (newRemainingValue < 0) {
                            this.redeemingTooMuch = true;
                            return this.formatCurrency(0);
                        }

                        this.redeemingTooMuch = false;
                        return this.formatCurrency(newRemainingValue);

                    }
                    else if (this.giftCard.backingType.toLowerCase() === "units") {
                        const remove = Math.trunc(Number(this.partialRedeemValue));

                        this.notRedeemingWholeUnits = false;

                        if (Number(this.partialRedeemValue) != remove) {
                            this.notRedeemingWholeUnits = true;
                            return 0;
                        }

                        let newRemainingUnits = this.giftCard.remainingUnits;

                        if (remove < 0) {
                            this.redeemingTooMuch = true;
                            return newRemainingUnits;
                        }

                        if (remove > 0) {
                            newRemainingUnits -= remove;
                        }

                        if (newRemainingUnits < 0) {
                            this.redeemingTooMuch = true;
                            return 0;
                        }

                        this.redeemingTooMuch = false;
                        return newRemainingUnits;
                    }
                },
                hasRedeemValueError() {
                    return this.redeemingTooMuch || this.notRedeemingWholeUnits;
                },
                redeemingTooMuchAlertMessage() {
                    const remove = Number(this.partialRedeemValue);

                    if (remove < 0) {
                        return `You cannot redeem a negative amount`;
                    }

                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        return `You cannot redeem more than ${this.formatCurrency(this.giftCard.remainingValue)}`;
                    }
                    else if (this.giftCard.backingType.toLowerCase() === "units") {
                        if (this.giftCard.remainingUnits === 1) {
                            return `You cannot redeem more than ${this.giftCard.remainingUnits} unit`;
                        }

                        return `You cannot redeem more than ${this.giftCard.remainingUnits} units`;
                    }

                    return "";
                },

                redeemPartiallyPlaceholder() {
                    if (this.giftCard.backingType.toLowerCase() === "currency") {
                        return Helpers.formatCurrency(0, this.giftCard.currency, this.giftCard.language, 0, 0);
                    }

                    return "0";
                }
            },

            validations: {
                partialRedeemValue: {
                    valid: function (value) {
                        if (!this.redeemingPartiallyStarted) {
                            return true;
                        }

                        const remove = Number(value);

                        if (remove <= 0) {
                            return false;
                        }

                        if (this.giftCard.backingType.toLowerCase() === "currency") {
                            if (this.giftCard.remainingValue - remove < 0) {
                                return false;
                            }

                        }
                        else if (this.giftCard.backingType.toLowerCase() === "units") {
                            if (this.giftCard.remainingUnits - Math.trunc(remove) < 0) {
                                return false;
                            }
                        }

                        return value > 0;
                    }
                }
            },
            methods: {
                formatCurrency(value: Number) {
                    return Helpers.formatCurrency(value, this.giftCard.currency, this.giftCard.language, 0, 4);
                },

                async redeemFully() {
                    this.redeemingFully = true;

                    let redeemRequest = RedeemRequest.fully(this.giftCard.id);

                    let self = this;
                    try {
                        await this.$store.dispatch('giftCard/redeem', redeemRequest);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    } finally{
                        self.redeemingFully = false;
                        self.v$.$reset();
                    }

                    this.$emit("redeemCompleted");
                },

                async redeemOneUnit() {
                    this.redeemingFully = true;

                    let redeemRequest = RedeemRequest.partially(this.giftCard.id, 1);

                    try {
                        await this.$store.dispatch('giftCard/redeem', redeemRequest);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }

                    this.redeemingFully = false;
                    this.v$.$reset();

                    this.$emit("redeemCompleted");
                },

                async redeemPartially() {
                    this.$emit("redeemStarted");

                    this.redeemingPartiallyStarted = true;

                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        this.redeemingPartiallyStarted = false;
                        this.redeemPartiallyInputFocus();
                        return;
                    }

                    this.redeemingPartially = true;

                    let redeemRequest = RedeemRequest.partially(this.giftCard.id,
                                                                this.partialRedeemValue);

                    try {
                        await this.$store.dispatch('giftCard/redeem', redeemRequest);
                    } catch (e) {
                        const ex = e as FetchRejectReason;

                        if (ex.responseStatus == 401) {
                            return;
                        }
                    }

                    this.redeemingPartially = false;
                    this.openRedeemingPartiallyPanel = false;
                    this.redeemingPartiallyStarted = false;
                    this.partialRedeemValue = '';
                    this.v$.$reset();

                    this.$emit("redeemCompleted");
                },

                redeemPartiallyInputFocus() {
                    document.getElementById("RedeemPartiallyInput").focus();
                },
            }
        });
