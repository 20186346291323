
import {defineComponent} from "vue"
import Helpers from "@/Helpers";

export default defineComponent({
    name: "remaining-balance",
    props: {
        backingType: {
            type: String,
            required: true,
            default: "Currency"
        },
        currency: {
            type: String,
            required: false,
            default: null
        },
        language: {
            type: String,
            required: false
        },
        remainingValue: {
            type: Number,
            required: false,
            default: null
        },
        remainingUnits: {
            type: Number,
            required: false,
            default: null
        },
        initialUnits: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        remainingBalanceText(): string {
            if (this.backingType === "Currency") {
                return `${Helpers.formatCurrency(this.remainingValue, this.currency, this.language)} remaining`;
            }

            if (this.backingType === "Units" && this.initialUnits) {
                return `${this.remainingUnits}/${this.initialUnits} remaining`;
            }

            return '';
        }
    }
});
