import { BaseApi } from "@/api/baseApi";
import { CompanyDetails } from "@/api/company";

export default class LoginApi extends BaseApi {
    static async login(request: LoginRequest): Promise<LoginResponse> {
        return this.post('login', request);
    }
}

export class LoginRequest {
    companyId: string;
    locationId: string;
    userId: string;
    email: string;
    pin: string;
    allowRedirect: boolean;

    static withEmail(email: string, pin: string, companyId: string = null, locationId: string = null): LoginRequest {
        return { email, pin, companyId, allowRedirect: true, locationId } as LoginRequest;
    }

    static withId(userId: string, companyId: string, pin: string, locationId: string = null) {
        return { userId, companyId, pin, allowRedirect: true, locationId } as LoginRequest;
    }
}

export interface LoginResponse {
    companyId: string;
    company: CompanyDetails;
    companies: Array<LoginResponseCompany>;
    locationId: string;
    locations: LoginResponseLocations;
    user: LoginResponseUser;
}

interface LoginResponseUser {
    id: string;
    avatarUrl: string;
    displayName: string;
    email: string;
    initials: string;
    canRedeemExpiredGiftCards: boolean;
    canRedeemLocationRestrictedGiftCards: boolean;
}

export interface LoginResponseCompany {
    id: string;
    wentLiveOn: string;
    name: string;
    canLogin: boolean;
    disabledMessage: string;
}

interface LoginResponseLocations {
    allLocations: Array<LoginResponseLocation>;
    userLocations: Array<LoginResponseLocation>;
}

export interface LoginResponseLocation {
    id;
    name;
}
