
    import { defineComponent } from 'vue';
    import SubmitButton from "@/components/SubmitButton.vue";
    import useVuelidate from "@vuelidate/core";
    import GiftCardSearchAndScan from "@/components/GiftCardSearchAndScan.vue";
    import {SearchQuery} from "@/store/modules/search";

    export default defineComponent(
        {
            components: {
                'submit-button': SubmitButton,
                'search-and-scan': GiftCardSearchAndScan
            },
            props: {
                companyId: String
            },
            data: function () {
                return {
                    submitting: false,
                    query: ''
                };
            },
            setup() {
                return {v$: useVuelidate()}
            },
            mounted() {
                document.getElementById("search").focus();
            },
            validations: {
                query: {
                    valid: function (value) {
                        return !!value && value.length >= 3;
                    }
                }
            },
            provide() {
                return {
                    "$parent.v$": this.v$
                };
            },
            computed: {
                company() {
                    return this.$store.state.company;
                },
                user() {
                    return this.$store.state.user;
                }
            },
            methods: {
                async submit() {
                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    this.submitting = true;

                    try {
                        await this.$store.dispatch('search/search', SearchQuery.search(this.query, 1));
                    }
                    catch {

                    }
                    finally {
                        this.submitting = false;
                    }
                }
            }
        });
