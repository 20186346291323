
    import { defineComponent } from 'vue';
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import useVuelidate from "@vuelidate/core";
    import GiftCardSearchAndScan from "@/components/GiftCardSearchAndScan.vue";
    import GiftCardRemainingBalanceText from "@/components/GiftCardRemainingBalanceText.vue";
    import GiftCardStatusBadge from "@/components/GiftCardStatusBadge.vue";
    import Alert from "@/components/Alert.vue";
    import router from "@/router";
    import { SearchResult } from "@/api/search";
    import { ChevronRightIcon, UserIcon } from "@heroicons/vue/24/solid";
    import { CreditCardIcon, QrCodeIcon, ArrowLeftIcon } from "@heroicons/vue/24/outline";
    import { CompanyState } from "@/store/modules/company";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";
    import StatusBadge from "@/components/GiftCardStatusBadge.vue";
    import {SearchQuery} from "@/store/modules/search";

    export default defineComponent(
        {
            components: {
                StatusBadge,
                'submit-button': SubmitButton,
                'redeem-header': Header,
                'alert': Alert,
                'search-and-scan': GiftCardSearchAndScan,
                'remaining-balance': GiftCardRemainingBalanceText,
                'status-badge': GiftCardStatusBadge,
                'loading-spinner': LoadingSpinner,
                ChevronRightIcon,
                UserIcon,
                CreditCardIcon,
                QrCodeIcon,
                ArrowLeftIcon
            },
            props: {
                q: String,
                p: String
            },
            setup() {
                return { v$: useVuelidate() }
            },
            data() {
                return {
                    query: this.q,
                    page: this.p ? parseInt(this.p) : 1,
                    searching: false,
                    selectedId: ''
                };
            },
            async created() {
                if (this.results == null || this.query == null) {
                    if (this.query) {
                        await this.$store.dispatch("search/search", SearchQuery.search(this.query, this.page));
                    }
                    else {
                        await router.replace({name: 'Redeem', params: {companyId: this.$store.state.company.id}});
                    }
                }
            },
            validations: {
                query: {
                    valid: function (value) {
                        if (!value) {
                            return false;
                        }

                        return value.length >= 3;
                    }
                }
            },
            provide() {
                return {
                    "$parent.v$": this.v$
                };
            },
            computed: {
                singularNomenclature() {
                    return this.$store.state.company.singularNomenclature;
                },
                company() : CompanyState {
                    return this.$store.state.company;
                },
                hasMore(){
                    return this.$store.state.search.hasMore;
                },
                results(): Array<SearchResult> {
                    return this.$store.state.search.results;
                },
                now() {
                    return this.$store.getters.liveUtcNow;
                }
            },
            methods: {
                getDetailsLink(giftCard) {
                    return this.$router.resolve({ name: 'Details', params: { id: giftCard.id } }).href;
                },

                async navigateToDetail(giftCard) {
                    this.selectedId = giftCard.id;

                    await this.$router.push({ name: 'Details', params: { id: giftCard.id } });

                    this.selectedId = "";
                },

                async nextPage() {
                    this.page = this.page + 1;
                    await this.submit();
                },

                async newSearch() {
                    this.page = 1;
                    await this.submit();
                },

                async submit() {
                    this.v$.$touch();

                    if (this.v$.$invalid) {
                        return;
                    }

                    this.searching = true;

                    try {
                        await this.$store.dispatch('search/search', SearchQuery.search(this.query, this.page));
                    } finally {
                        this.searching = false;
                    }
                }
            }
        });
