
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
    import useVuelidate from "@vuelidate/core";
    import { LoginRequest } from "@/api/login";
    import SubmitButton from "@/components/SubmitButton.vue";
    import Header from "@/components/Header.vue";
    import { UserState } from './store/modules/user';
    import {CompanyState} from "@/store/modules/company";
    import Helpers from "@/Helpers";
    import FocusDirective from "@/mixins/FocusDirective";
    import LoadingSpinner from "@/components/LoadingSpinner.vue";

    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            ExclamationCircleIcon,
            SubmitButton,
            'redeem-header': Header,
            'loading-spinner': LoadingSpinner
        },
        data() {
            return {
                pin: '',
                submittingLogin: false,
                onlineMessageTimerId: null,
                showOfflineMessage: false,
                key: Date.now()
            }
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [
            FocusDirective
        ],
        validations: {
            pin: {
                valid: function (value: number) {
                    if (!value) {
                        return false;
                    }

                    return value.toString().length >= 4;
                }
            }
        },
        computed: {
            open(){
                return this.loginExpired;
            },
            route() {
                return this.$route;
            },
            user() : UserState {
                return this.$store.state.user;
            },
            company() : CompanyState {
                return this.$store.state.company;
            },
            location() {
                return this.$store.state.location;
            },
            online() {
                return this.$store.state.online;
            },
            loginExpired() {
                return this.route.meta && this.route.meta.requiresAuth && this.$store.state.user.loginExpired;
            },
            store() {
                return this.$store.state;
            }
        },
        methods: {
            async submitLogin() {
                this.submittingLogin = true;

                let loginRequest = LoginRequest.withEmail(this.user.email, this.pin, this.company?.id, this.location?.id);
                loginRequest.allowRedirect = false;

                const incorrectPinQueue = 'incorrect-pin';
                Helpers.clearNotifications(incorrectPinQueue);

                try {
                    await this.$store.dispatch('user/refreshLogin', loginRequest);

                    await this.$store.dispatch('dispatchIntercepted401Action');
                }
                catch (ex) {
                    if (ex.responseStatus == 401 || ex.responseStatus == 400) {
                        Helpers.showErrorNotification("Incorrect PIN, please try again", null, incorrectPinQueue);

                        if (this.$refs.reauthorisePinEntry) {
                            this.$refs.reauthorisePinEntry.focus();
                        }
                    }
                }
                finally {
                    this.pin = "";
                    this.submittingLogin = false;
                }
            },
            async signOut(){
                this.pin = "";

                await this.$store.dispatch('user/signout');
            }
        },
        watch: {
            online(online) {
                if (!online) {
                    this.onlineMessageTimerId = setTimeout(() => {
                        this.showOfflineMessage = true;
                    }, 2000);
                    return;
                }

                if (this.onlineMessageTimerId) {
                    this.showOfflineMessage = false;
                    clearTimeout(this.onlineMessageTimerId);
                }
            }
        }
    }
