
    import { defineComponent } from 'vue'
    import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'

    export default defineComponent(
        {
            name: "select-picker",
            components: {
                Listbox,
                ListboxButton,
                ListboxLabel,
                ListboxOption,
                ListboxOptions,
                CheckIcon,
                ChevronDownIcon
            },
            props: {
                modelValue: String,
                title: String,
                label: String,
                tabindex: {
                    type: Number,
                    default: null,
                    required: false
                },
                list: {
                    type: Array,
                    default: null,
                    required: true
                },
                optionRenderer: {
                    type: Function,
                    required: false
                },
                valueProp: String,
                textProp: String,
                multiple: Boolean,
                search: Boolean
            },
            watch: {
                selected(newValue) {
                    this.$emit('update:modelValue', newValue);
                },
                modelValue() {
                    this.selected = this.modelValue;
                }
            },
            data() {
                return {
                    value: this.modelValue,
                    isMultiple: this.multiple,
                    selected: this.modelValue
                }
            },
            computed: {
                getSelectedText() {
                    if (this.selected) {
                        const option = this.list.find(o => o[this.valueProp
                                                             ? this.valueProp
                                                             : "value"] == this.selected);

                        if (option) {
                            return option[this.textProp ? this.textProp : "text"];
                        }
                    }

                    return this.title ? this.title : "Please select…";
                }
            },
            methods: {
                getValue(option) {
                    if (this.valueProp) {
                        return option[this.valueProp];
                    }

                    return option.value ? option.value : option
                },

                renderText(option) {
                    if (this.optionRenderer) {
                        return this.optionRenderer(option);
                    }

                    if (this.textProp) {
                        return option[this.textProp];
                    }

                    return option.text ? option.text : option;
                }
            }
        });
