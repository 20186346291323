import { Module } from "vuex";
import Helpers from "@/Helpers";
import CloudBedsApi, {
    CloudbedsRedeemRequest,
    CloudbedsRefreshReservationRequest,
    SearchRequest
} from "@/api/cloudbeds";
import { Intercepted401Action } from "@/store";

export interface CloudbedsState {
    isHotelsListLoaded: boolean;
    hotels: Array<HotelState>;
    selectedHotelId: string;
    searchResults: Array<SearchResultState>;
    selectedReservation: SearchResultState;
    isSelectedReservationStale: boolean;
    tokenExpired: boolean;
}

export interface HotelState {
    propertyId: string;
    propertyName: string;
}

export interface SearchResultState {
    reservationId: string;
    propertyId: string;
    guestName: string;
    startDate: Date;
    endDate: Date;
    balance: number;
    roomName: string;
    status: string;
}

function getDefaultState() : CloudbedsState {
    return <CloudbedsState>{
        isHotelsListLoaded: false,
        hotels: [] as Array<HotelState>,
        searchResults: [] as Array<SearchResultState>,
        selectedReservation: undefined as SearchResultState,
        isSelectedReservationStale: false,
        selectedHotelId: '',
        tokenExpired: false
    };
}

const state = getDefaultState();

const actions = {
    async listHotels({ commit }){
        try {
            const response = await CloudBedsApi.listHotels();

            commit('setHotels', response.hotels);

            if (response.hotels.length == 1) {
                commit('selectHotel', response.hotels[0].propertyId);
            }
        } catch (fetchRejection) {
            commit('setHotels', []);

            Helpers.showErrorNotificationFromFetchRejection(fetchRejection);
        }
    },

    async selectHotel({ commit }, hotelId: string){
        commit('selectHotel', hotelId);
        commit('resetSearch');
    },

    async search({state, commit, rootState}, request: SearchRequest){
        try {
            commit('markAsStale');

            const response = await CloudBedsApi.search(request);

            commit('setSearchResults', response);

            if (response.length == 0) {
                Helpers.showWarningNotification(`Could not find any reservations for the search term <code>${request.roomName}</code>, please try again`);
            }
        }
        catch (ex) {
            if (ex.responseStatus == 404) {
                Helpers.showWarningNotification(`Could not find reservation, please try again`);
            }

            throw ex;
        } finally {
            commit('markAsNotStale');
        }
    },

    async refreshReservation({state, commit}, request: CloudbedsRefreshReservationRequest){
        try {
            commit('markAsStale');

            const response = await CloudBedsApi.getReservation(request.propertyId, request.reservationId);

            commit('setSelectedReservation', response);
        }
        catch (ex) {
            if (ex.responseStatus == 404) {
                Helpers.showWarningNotification(`Could not find reservation, please try again`);
            }

            throw ex;
        } finally {
            commit('markAsNotStale');
        }
    },

    async redeem({ state, dispatch, commit, rootState }, request: CloudbedsRedeemRequest) {
        commit("setIntercepted401Action",
               { action: "cloudbeds/redeem", payload: request } as Intercepted401Action,
               { root: true });

        try {
            const response = await CloudBedsApi.redeem(request);

            commit('giftCard/setRedeemResult', response, { root: true });

            commit('search/setQuery', null, { root: true });
        } catch (ex) {
            commit('giftCard/setRedeemResult', null, { root: true });

            if (ex?.responseStatus != 0 && ex?.responseStatus != 401) {
                Helpers.showWarningNotification(ex?.responseContent,
                                                `Could not redeem ${rootState.company.singularNomenclature.toLowerCase()}, please try again`);
            }
        }

        await dispatch('giftCard/refreshDetails', rootState.giftCard.id, { root: true });
        await dispatch('refreshReservation', { reservationId: request.reservationId, propertyId: request.propertyId } as CloudbedsRefreshReservationRequest);
    },

    async reset({commit}){
        commit('reset');
    },

    async resetSearch({commit}){
        commit('resetSearch');
    },

    async expiredToken({commit}){
        commit('expiredToken');
    },

    async resetHotel({commit}){
        commit('resetHotel');
    }
};

const mutations = {
    markAsStale (state){
        state.isSelectedReservationStale = true;
    },

    markAsNotStale(state) {
        state.isSelectedReservationStale = false;
    },

    setHotels (state: CloudbedsState, hotels: Array<HotelState>){
        state.isHotelsListLoaded = true;
        state.hotels = hotels;
    },

    selectHotel(state: CloudbedsState, hotelId: string){
        state.selectedHotelId = hotelId;
    },

    setSearchResults(state: CloudbedsState, searchResults: SearchResultState[]){
        state.searchResults = searchResults;
        state.isSelectedReservationStale = false;
    },

    setSelectedReservation(state: CloudbedsState, reservation: SearchResultState){
        state.selectedReservation = reservation;
        state.isSelectedReservationStale = false;
    },

    reset(state){
        Object.assign(state, getDefaultState());
    },

    resetSearch(state: CloudbedsState){
        const defaultState = getDefaultState();
        state.searchResults = defaultState.searchResults;
        state.selectedReservation = defaultState.selectedReservation;
    },

    expiredToken(state: CloudbedsState){
        state.tokenExpired = true;
    },

    resetHotel(state: CloudbedsState){
        state.selectedHotelId = '';
    }
}

const getters = {
    selectedHotel(state: CloudbedsState) {
        return state.hotels.find(x => x.propertyId == state.selectedHotelId);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
} as unknown as Module<any, any>
